import React, { useState, useEffect } from "react";
// import { TIFFViewer } from "react-tiff";
// import "react-tiff/dist/index.css";

const imageSizes = {
  thumb: { width: "75px", height: "75px" },
  "banner@2x": { width: "1170px", height: "290px" },
  web: { width: "914px", height: "530px" },
  "web@2x": { width: "1810px", height: "970px" },
  "web@3x": { width: "2450px", height: "1330px" },
  "web@4x": { width: "3730px", height: "2050px" },
  headshot: { width: "140px", height: "390px" },
  featured: { width: "530px", height: "236px" },
  "featured@2x": { width: "1170px", height: "582px" },
  "featured@3x": { width: "1810px", height: "928px" },
};

const cssClassess = {
  banner: {
    width: "100%",
    "aspect-ratio": "3.2",
  },
  featured: {
    "aspect-ratio": "1.85",
    "object-fit": "cover",
    width: "362px",
    height: "196px",
  },
  headshot: {
    width: "100%",
    height: "100%",
    "aspect-ratio": "1.25",
    "object-fit": "cover",
  },
};

const ImageComponent = ({
  src,
  alt = "image",
  style = {},
  ref,
  className = "",
  onError,
  onLoad,
  onClick,
  draggable = false,
  height,
  width,
  type = "",
  crossOrigin
}) => {
  const [extension, setExtension] = useState("");

  useEffect(() => {
    if (src) {
      const ext = src?.split(".").pop().split("?")[0].toLowerCase();
      setExtension(ext);
    }
  }, [src]);

  if (!src) {
    return <div style={{ color: "red" }}>No image URL provided.</div>;
  }

  const imgStyle = {
    ...style,
    height: height || style.height,
    width: width || style.width,
  };

  return (
    <>
      {extension == "tif" || extension == "tiff" ? (
        <></>
      ) : (
        <img
          ref={ref}
          src={src}
          alt={alt}
          style={imgStyle}
          className={className || undefined}
          draggable={draggable}
          crossOrigin={crossOrigin}
          onError={(e) => {
            if (onError) {
              onError(e);
            } else {
              e.target.style.display = "none";
            }
          }}
          onLoad={(e) => {
            if (onLoad) {
              onLoad(e);
            }
          }}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
          }}
        />
      )}
    </>
  );
};

export default ImageComponent;
