import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import CollapsableSection from '../../components/collapsableSection';
import TextEditor from '../../components/editors/textEditor';
import ImagePreview from '../../components/featured/editInfo/imagePreview';
import Spinner from '../../components/spinner';
import { useHistory } from 'react-router-dom';
import { uniqBy } from "lodash";
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { customSelectStyle, personName, handleDragDropReorder, featuredHomepageJson } from '../../helpers';
import uniqid from 'uniqid'
import Dragula from 'react-dragula'
import FeaturedTitle from './title';
import FeaturedCardEntityOfDay from '../../components/featured/featuredCardEntityOfDay';
import SelectPeople from '../../components/selects/person';
import SelectSite from '../../components/selects/site';
import SelectEvent from '../../components/selects/event';
import SelectTour from '../../components/selects/tour';
import SelectMap from '../../components/selects/map';
import SelectPodcast from '../../components/selects/podcast';
import SelectPlaylist from '../../components/selects/playlist';
import SelectEventSeries from '../../components/selects/eventSeries';
import SelectRecipe from '../../components/selects/recipe';
import ImageViewer from '../../components/viewers/image';
import { Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import ImageComponent from '../../components/image';
import Button from '../../components/buttons/default';
import { toast } from 'react-toastify';
import moment from "moment";
import Icon from '../../components/viewers/icon';
const options = require('../../data/featured_entity_of_day.json')
const _api = require('../../api');

const types = [
    { label: "SITE", value: "site" },
    { label: "PERSON / ORG / PUBLICATION", value: "person" },
    { label: "EVENT", value: "event" },
    { label: "TOUR", value: "tour" },
    { label: "MAP", value: "map" },
    { label: "PODCAST", value: "podcast" },
    { label: "PLAYLIST", value: "playlist" },
    { label: "EVENT SERIES", value: "event_series" },
    { label: "RECIPE", value: "recipe" }
];

const SiteOfTheDay = forwardRef((props, ref) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [editedIndex, setEditedIndex] = useState(-1);
    const [siteOfDay, setSiteOfDay] = useState([])
    const [entityOfDay, setEntityOfDay] = useState('site')
    const [display, setDisplay] = useState(false)
    const [date, setDate] = useState('');
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [siteDescription, setSiteDescription] = useState('')
    const [images, setImages] = useState([
        { link: "1", type: "", name: "" },
        { link: "2", type: "", name: "" },
        { link: "3", type: "", name: "" }
    ]);
    const imageRef = useRef(null);
    const [deleteEntity, setDeleteEntity] = useState(false)
    const [scale, setScale] = useState({ scaleX: '', scaleY: '' });
    const [spinner, setSpinner] = useState(false)
    const [crop, setCrop] = useState({ aspect: 16 / 9 });
    const [imageEditIndex, setImageEditIndex] = useState()
    const [imageEditData, setImageEditData] = useState({})
    const [region, setRegion] = useState({ left: 0, top: 0, width: 0, height: 0 });
    const [show, setShow] = useState(false)
    const searchSiteofDay = useSelector((state) => state.search);
    const featured = useSelector((state) => state.featured);
    const site_of_the_day = featured.site_of_the_day || []
    let selectedDate = siteOfDay[editedIndex]?.dates?.map((elem) => { return { value: elem.year, label: elem.year } })
    selectedDate = uniqBy(selectedDate, 'label')
    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = (props?.page !== 'home') ? 'dragula-container-site-of-day' : ''

    useEffect(() => {
        handleDragDrop()
        // eslint-disable-next-line
    }, [dragula])

    const handleDragDrop = () => {
        const siteOfDay = site_of_the_day?.[0]?.data?.site_of_the_day || []
        if (siteOfDay?.length > 0) {
            let containers = []
            containers.push(document.getElementById(dragulaContainer))
            dragulaRef.current = Dragula(containers, {})
            dragulaRef.current.on('drop', () => {
                setEditedIndex(-1)
                const items = handleDragDropReorder(siteOfDay, dragulaContainer)
                site_of_the_day[0].edited = true
                site_of_the_day[0].data.site_of_the_day = items
                dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
                setDragula(uniqid())
            })
        }

    }
    // Dragula End

    // eslint-disable-next-line 
    useEffect(() => { formatSiteOfDay(); }, [site_of_the_day?.length, dragula])

    const formatSiteOfDay = () => {
        let data = []
        if (site_of_the_day)
            for (let i in site_of_the_day) {
                if (site_of_the_day[i]?.data?.site_of_the_day) {
                    for (let j in site_of_the_day[i].data.site_of_the_day) {
                        site_of_the_day[i].data.site_of_the_day[j].section_index = i
                        site_of_the_day[i].data.site_of_the_day[j].entity_index = j
                        if (!site_of_the_day[i].data.site_of_the_day[j].description) addDateDescription(i, j)
                        if (site_of_the_day[i].data.site_of_the_day[j].entity_type === 'event') setEventDate(i, j)
                        data.push(site_of_the_day[i].data.site_of_the_day[j])
                    }
                }
            }
        setSiteOfDay(data);
    }

    const addDateDescription = (i, j) => {
        site_of_the_day[i].data.site_of_the_day[j].description = site_of_the_day[i].data.site_of_the_day[j]?.dates?.filter((e) => e.description !== '')[0]?.description
        dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
    }

    const setEventDate = (i, j) => {
        const returnYear = (timestamp) => {
            return new Date(timestamp * 1000).getFullYear();
        }
        if (!site_of_the_day[i].data.site_of_the_day[j]?.occurrence) {
            if (site_of_the_day[i].data.site_of_the_day[j]?.dates?.length > 0) {
                const count = site_of_the_day[i].data.site_of_the_day[j]?.dates?.filter((elem => !elem.year))?.length
                if (count !== 0) {
                    site_of_the_day[i].data.site_of_the_day[j].dates.forEach((elem) => elem.year = returnYear(elem.start_date))
                    dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
                }
            }
        }
        if (site_of_the_day[i].data.site_of_the_day[j]?.occurrence) {
            site_of_the_day[i].data.site_of_the_day[j].dates = [{ year: null, start_date: null }]
            site_of_the_day[i].data.site_of_the_day[j].dates.forEach((elem) => { elem.year = returnYear(site_of_the_day[i].data.site_of_the_day[j]?.occurrence); elem.start_date = site_of_the_day[i].data.site_of_the_day[j]?.occurrence })
            dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
        }
    }

    const handleInputChange = (value, type, id) => {
        let id_index = siteOfDay[editedIndex].section_index
        let id_site_index = siteOfDay[editedIndex].entity_index
        site_of_the_day[id_index].edited = true
        if (type === 'name') {
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].name = value
        }
        if (type === 'date') {
            let dates = site_of_the_day[id_index].data.site_of_the_day[id_site_index].dates
            let index = dates.findIndex((elem) => elem.year === value.value)
            let selectedDate = dates.splice(index, 1)
            dates.unshift(selectedDate[0])
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].dates = dates
        }
        if (type === 'description' && value) {
            if (!site_of_the_day[id_index].data.site_of_the_day[id_site_index].description_original) {
                site_of_the_day[id_index].data.site_of_the_day[id_site_index].description_original = site_of_the_day[id_index].data.site_of_the_day[id_site_index].description
            }
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].description = value
        }
        if (type === 'first_name') {
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].first_name = value
        }
        if (type === 'last_name') {
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].last_name = value
        }
        dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
    }

    const handleDeleteSite = async () => {
        setDisplay(true)
        setDeleteEntity(true)
        let id_index = siteOfDay[editedIndex].section_index
        let id_site_index = siteOfDay[editedIndex].entity_index
        site_of_the_day[id_index].edited = true
        site_of_the_day[id_index].data.site_of_the_day.splice(id_site_index, 1)
        dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
        setDisplay(false)
        //to refresh Call function Again
        formatSiteOfDay()
        setEditedIndex(editedIndex - 1)
    }

    const handleRedirect = () => {
        dispatch({ type: 'search', payload: { ...searchSiteofDay, site_of_the_day: siteOfDay, selectedResult: [], title: `HOMEPAGE - ${entityOfDay} of the day`, filterLive: true } })
        history.push(`/featured/searchList/site_of_the_day/${entityOfDay}`)
    }

    const handleDescriptionReset = () => {
        let id_index = siteOfDay[editedIndex].section_index
        let id_site_index = siteOfDay[editedIndex].entity_index
        if (site_of_the_day[id_index].data.site_of_the_day[id_site_index].description_original) {
            site_of_the_day[id_index].edited = true
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].description = site_of_the_day[id_index].data.site_of_the_day[id_site_index].description_original
            dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
        }
    }

    const handleTypeChange = (index, selectedType) => {
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[index] = {
                ...updatedImages[index],
                type: selectedType.value,
                name: { type: selectedType.value, image: "", text: "" },
            };
            return updatedImages;
        });
    };


    const handleNameChange = (index, selectedName) => {
        const updatedImages = [...images];
        updatedImages[index].name = selectedName;
        setImages(updatedImages);
    };

    const renderNameComponent = (type, index) => {
        switch (type) {
            case "person":
                return <SelectPeople value={images[index].name} onChange={(value) => handleNameChange(index, value)} />;
            case "site":
                return <SelectSite value={images[index].name} onChange={(value) => handleNameChange(index, value)} />;
            case "event":
                return <SelectEvent value={images[index].name} onChange={(value) => handleNameChange(index, value)} />;
            case "tour":
                return <SelectTour value={images[index].name} onChange={(value) => handleNameChange(index, value)} />;
            case "map":
                return <SelectMap value={images[index].name} onChange={(value) => handleNameChange(index, value)} />;
            case "podcast":
                return <SelectPodcast value={images[index].name} onChange={(value) => handleNameChange(index, value)} />;
            case "playlist":
                return <SelectPlaylist value={images[index].name} onChange={(value) => handleNameChange(index, value)} />;
            case "event_series":
                return <SelectEventSeries value={images[index].name} onChange={(value) => handleNameChange(index, value)} />;
            case "recipe":
                return <SelectRecipe value={images[index].name} onChange={(value) => handleNameChange(index, value)} />;
            default:
                return <Select options={[]} placeholder="Select Type First" isDisabled />;
        }
    };

    const onFeaturedSave = async () => {
        const missingFields = [];
        if (!date) missingFields.push("Date");
        if (!title) missingFields.push("Title");
        const missingImages = images?.slice(0, 3).map((img, index) => (!img?.name ? `Image ${index + 1}` : null)).filter(Boolean);
        missingFields.push(...missingImages);

        if (missingFields.length > 0) {
            toast.error(`Please fill the following fields: ${missingFields.join(", ")}`);
            return;
        }
        if (!deleteEntity) {
            setSpinner(true);
            let imageData = {}
            try {
                imageData = await _api.featured.createHomepageImage({
                    images,
                    date,
                    title,
                    text
                })
            } catch (error) {
                setSpinner(false)
                toast.error(error)
            }
            let obj = featuredHomepageJson;
            obj.featured_data = {
                images,
                date,
                title,
                text
            }
            obj.image.id = imageData?.image?.id;
            obj.image.url = imageData?.image?.url;
            obj.image_id = imageData?.image?.id;
            obj.description = siteDescription;
            if (editedIndex == -2) {
                site_of_the_day[0].data.site_of_the_day.push({ ...obj })
                dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
            }
            if (editedIndex > -1) {
                site_of_the_day[0].data.site_of_the_day[editedIndex] = obj;
                dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
            }
            setSpinner(false)
        }

    }

    useImperativeHandle(ref, () => ({
        childFunction: async () => {
            try {
                await onFeaturedSave();
            } catch (error) {
                console.error("Error in childFunction:", error);
                throw error;
            }
        }
    }));


    const editImage = (index) => {
        setShow(true)
        setImageEditIndex(index)
        setImageEditData(images[index]?.name)
    }

    const handleSaveCropedImage = async () => {
        if (!imageRef.current || !crop.width || !crop.height) {
            console.error("No image or invalid crop region.");
            toast.error('No image or invalid crop region.');
            return;
        }
        try {
            setSpinner(true);
            const croppedBlob = await cropImageBlob(imageRef.current, crop);
            if (!croppedBlob) {
                console.error("Cropping failed.");
                toast.error('Cropping failed.');
                return;
            }

            const mimeType = croppedBlob.type || "image/jpeg";
            const extension = mimeType.split("/")[1] || "jpeg";

            let image = await _api.image.update({
                type: "image",
                created_via: "ADMIN"
            });

            const filename = `img/${image.id}/original.${extension}`;

            const uploadUrl = await _api.file.getUploadUrl({
                name: filename,
                contentType: mimeType
            });

            await _api.file.upload(uploadUrl.url, croppedBlob, {
                headers: { "Content-Type": mimeType }
            });

            image.url = uploadUrl.url.split("?")[0];
            await _api.image.update(image);

            // 5. Update state
            setImages((prevImages) =>
                prevImages.map((img, index) =>
                    index === imageEditIndex
                        ? {
                            ...img,
                            name: {
                                ...img.name,
                                crop_image: image
                            }
                        }
                        : img
                )
            );

            toast.success('Your image has been cropped');
            setSpinner(false);
        } catch (error) {
            console.error("Error saving cropped image:", error);
            toast.error('Error saving cropped image');
        }
    };

    const handleLoad = ({ target: img }) => {
        imageRef.current = img;
        const { offsetHeight, offsetWidth, naturalHeight, naturalWidth } = img;

        setScale({
            scaleX: naturalWidth / offsetWidth,
            scaleY: naturalHeight / offsetHeight
        });
        const aspect = 1 / 1.625;
        if (!imageRef.current) return;

        const imageWidth = imageRef.current.width;
        const imageHeight = imageRef.current.height;

        const newWidth = aspect ? Math.min(imageWidth, imageHeight * aspect) : 50;
        const newHeight = aspect ? Math.min(imageHeight, imageWidth / aspect) : 50;

        const x = (imageWidth - newWidth) / 2;
        const y = (imageHeight - newHeight) / 2;

        const newCrop = {
            unit: '%',
            x: (x / imageWidth) * 100,
            y: (y / imageHeight) * 100,
            width: (newWidth / imageWidth) * 100,
            height: (newHeight / imageHeight) * 100,
            aspect
        };

        setCrop(newCrop);
    };
    const [croppedImages, setCroppedImages] = useState([]);

    const cropImage = async () => {
        setRegion({
            left: parseInt(crop.x * scale.scaleX),
            top: parseInt(crop.y * scale.scaleY),
            width: parseInt(crop.width * scale.scaleY),
            height: parseInt(crop.height * scale.scaleX)
        });
        if (imageRef.current && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImage(imageRef.current, crop);
            const updatedImages = [...croppedImages];
            updatedImages[imageEditIndex] = croppedImageUrl;
            setCroppedImages(updatedImages);
        }
    }

    const cropImageBlob = (image, crop) => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;

            canvas.width = crop.width;
            canvas.height = crop.height;

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            canvas.toBlob((blob) => {
                resolve(blob);
            }, "image/jpeg", 1.0);
        });
    };
    const getCroppedImage = (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    const url = URL.createObjectURL(blob);
                    resolve(url);
                }
            }, 'image/jpeg');
        });
    };
    useEffect(() => {
        const data = siteOfDay[editedIndex]?.featured_data
        if (data) {
            setImages(data?.images)
            setTitle(data?.title)
            setDate(data?.date)
            setText(data?.text)
            setSiteDescription(siteOfDay[editedIndex]?.description)
        } else {
            setImages([
                { link: "1", type: "", name: "" },
                { link: "2", type: "", name: "" },
                { link: "3", type: "", name: "" }
            ])
            setTitle('')
            const start_date = featured?.start_date ? JSON.parse(featured.start_date) : '';
            const end_date = featured?.end_date ? JSON.parse(featured.end_date) : start_date;

            const formattedStart = moment(start_date).format("MMMM Do YYYY");
            const formattedEnd = moment(end_date).format("MMMM Do YYYY");

            setDate(start_date === end_date ? formattedStart : `${formattedStart} - ${formattedEnd}`);
            setText('')
            setSiteDescription('')
        }
    }, [editedIndex, featured])

    const clearImageData = (index) => {
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[index] = { link: index + 1, type: "", name: "" };
            return updatedImages;
        });
    };

    const editEntity = (entity_type, index) => {
        if (entity_type == "featured"){
            setEntityOfDay("featured")
        }
        if (props?.page !== 'home') setEditedIndex(index);
    }
    return (
        <div>
            <FeaturedTitle title='Featured Sites on the Landing page' page={props?.page} />
            <hr className={`cnow-hr ${props?.page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="FEATURED" count='1 item'
                    button={{
                        close: { show: true, onHandle: () => { history.push(`/featured`) } }
                    }}
                    disable={props?.page === 'home' ? true : false}
                >
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="OF THE DAY"
                        count={siteOfDay.length > 1 ? `${siteOfDay?.length} items` : `${siteOfDay?.length} item`}
                        button={{
                            search: { show: entityOfDay != "featured" && true, onHandle: () => { handleRedirect() } },
                            edit: { show: entityOfDay !== "featured", onHandle: () => setEditedIndex(0) },
                            add: { show: entityOfDay == "featured", onHandle: () => { if (entityOfDay == "featured") { setEditedIndex(-2) } else { setEditedIndex(0) } } },
                        }}
                        page={props?.page}
                        select={{
                            entity: { show: true, options: options, onHandle: (data) => { setEntityOfDay(data.value) }, value:entityOfDay, defaultValue: options[0] },
                        }}>
                        <Spinner display={display} >
                            <div id={dragulaContainer} key={dragula} className='row'>
                                {entityOfDay != "featured" && siteOfDay.map((item, index) => (
                                    <div id={item.id} key={index} className={`mt-2 col-auto dragula-item`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => editEntity(item?.entity_type, index)}>
                                        <FeaturedCardEntityOfDay data={item} entity_type={item?.entity_type} />
                                    </div>))
                                }
                                {
                                    entityOfDay == "featured" && (
                                        <>
                                            {siteOfDay.map((item, index) => (
                                                <div id={item.id} key={index} className={`mt-2 col-auto dragula-item`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (props?.page !== 'home') setEditedIndex(index); }}>
                                                    {item.type == "featured" && <FeaturedCardEntityOfDay data={item} entity_type={item?.entity_type} />}
                                                </div>))}
                                            {
                                                editedIndex !== -1 && (
                                                    <div className='d-flex align-items-center' style={{ gap: "8%" }}>
                                                        <div>
                                                            <div className="grid-container">
                                                                {images?.map((image, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className={`grid-item ${index === 0 ? 'main' : index === 1 ? 'first' : 'third'}`}
                                                                        onClick={() => editImage(index)}
                                                                    >
                                                                        {croppedImages[index] ? (
                                                                            <img src={croppedImages[index]} alt="Cropped" style={{ height: '100%', aspectRatio: "1/1.65" }} />
                                                                        ) : image.name.image ? (
                                                                            <ImageViewer
                                                                                entityId={image.name.image_id || image.name.image}
                                                                                entityType="image"
                                                                                url={image.name.image.startsWith('http') ? image.name.image : image.name.image_id}
                                                                                style={{ height: '100%' }}
                                                                                cssClass="headshot"
                                                                            />
                                                                        ) : (
                                                                            index + 1
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="text-box">
                                                                <div dangerouslySetInnerHTML={{ __html: siteDescription }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 d-flex flex-column justify-content-center align-items-center text-center">
                                                            <div
                                                                className="text-center text-white d-flex align-items-end justify-content-center mt-2"
                                                                style={{
                                                                    width: "fit-content",
                                                                    height: "120px",
                                                                    background: "rgb(194, 35, 38)",
                                                                    padding: "10px 20px",
                                                                }}
                                                            >
                                                                <div
                                                                    className="fw-bold text-nowrap"
                                                                    style={{
                                                                        fontFamily: "Helvetica, sans-serif",
                                                                        fontSize: "18px",
                                                                    }}
                                                                    dangerouslySetInnerHTML={{ __html: date }}
                                                                />
                                                            </div>

                                                            <div className="text-center p-3 mt-3 d-flex align-items-center flex-column">
                                                                <div
                                                                    className="text-white p-1 fw-bold"
                                                                    style={{
                                                                        background: "rgb(194, 35, 38)",
                                                                        fontFamily: "Helvetica, sans-serif",
                                                                        fontSize: "25px",
                                                                    }}
                                                                    dangerouslySetInnerHTML={{ __html: title }}
                                                                />
                                                                <p
                                                                    className="fw-bold col-md-8 text-center w-100"
                                                                    style={{
                                                                        fontSize: "20px",
                                                                        whiteSpace: "pre-line",
                                                                        lineHeight: "25px",
                                                                    }}
                                                                >
                                                                    <div style={{ fontFamily: "Helvetica, sans-serif", fontSize: "20px", fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: text }} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </Spinner>
                    </CollapsableSection>

                    {(editedIndex !== -1) && (siteOfDay[editedIndex]?.entity_type !== 'featured') && entityOfDay != "featured" && siteOfDay?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { window.open(`/${siteOfDay[editedIndex].entity_type !== 'person' ? siteOfDay[editedIndex].entity_type : 'people'}/${siteOfDay[editedIndex].entity_type === 'event' ? siteOfDay[editedIndex]?.parent_id ? siteOfDay[editedIndex]?.parent_id : siteOfDay[editedIndex].id : siteOfDay[editedIndex].id}/editor`) } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1); props.onUpdate(); } },
                            delete: { show: (siteOfDay.length > 1), onHandle: () => { handleDeleteSite(); props.onUpdate(); } }
                        }}
                    >
                        <div className='mt-2 mx-5'>
                            <div className='row'>
                                <div className='col-2'>
                                    <label className='cnow-form-label fw-bold'>Date</label>
                                    <Select key={`Select-${editedIndex}`} options={selectedDate} styles={customSelectStyle} placeholder={"Select"} onChange={(e) => { handleInputChange(e, 'date') }} defaultValue={selectedDate[0]} />
                                </div>
                                <div className='col-10'>
                                    {siteOfDay[editedIndex]?.entity_type !== 'person' && <> <label className='cnow-form-label fw-bold text-capitalize'>{`${siteOfDay[editedIndex]?.entity_type !== 'event_series' ? siteOfDay[editedIndex]?.entity_type : 'Event Series'} Title`}</label>
                                        <input key={`input-${editedIndex}-1`} name='name' type='text' className='form-control' value={site_of_the_day[siteOfDay[editedIndex].section_index].data.site_of_the_day[siteOfDay[editedIndex].entity_index].name} onChange={(e) => handleInputChange(e.target.value, 'name')} autoComplete="off" />
                                    </>}
                                    {siteOfDay[editedIndex]?.entity_type === 'person' && siteOfDay[editedIndex].type === 'person' && <div className='row'>
                                        <div className='col-auto'><label className='cnow-form-label fw-bold text-capitalize'>{`${siteOfDay[editedIndex]?.entity_type} First Name`}</label>
                                            <input key={`input-${editedIndex}-2`} name='name' type='text' className='form-control' value={site_of_the_day[siteOfDay[editedIndex].section_index].data.site_of_the_day[siteOfDay[editedIndex].entity_index]?.first_name} onChange={(e) => handleInputChange(e.target.value, 'first_name')} autoComplete="off" /> </div>
                                        <div className='col-auto'> <label className='cnow-form-label fw-bold text-capitalize'>{`${siteOfDay[editedIndex]?.entity_type} Last Name`}</label>
                                            <input key={`input-${editedIndex}-3`} name='name' type='text' className='form-control' value={site_of_the_day[siteOfDay[editedIndex].section_index].data.site_of_the_day[siteOfDay[editedIndex].entity_index]?.last_name} onChange={(e) => handleInputChange(e.target.value, 'last_name')} autoComplete="off" /></div>
                                    </div>}

                                    {siteOfDay[editedIndex]?.entity_type === 'person' && siteOfDay[editedIndex].type !== 'person' && <> <label className='cnow-form-label fw-bold text-capitalize'>{`${siteOfDay[editedIndex]?.entity_type} Name`}</label>
                                        <input key={`input-${editedIndex}-4`} name='name' type='text' className='form-control' value={site_of_the_day[siteOfDay[editedIndex].section_index].data.site_of_the_day[siteOfDay[editedIndex].entity_index]?.name} onChange={(e) => handleInputChange(e.target.value, 'name')} autoComplete="off" />
                                    </>
                                    }
                                </div>
                            </div>
                            <div>
                                <TextEditor key={`TextEditor-${editedIndex}`} data={site_of_the_day[siteOfDay[editedIndex].section_index].data.site_of_the_day[siteOfDay[editedIndex].entity_index].description} onChange={(data) => { handleInputChange(data, 'description', siteOfDay[editedIndex].id) }} limit="50" button={{ reset: { show: true, onHandle: () => { handleDescriptionReset() } } }}>
                                    <label className='cnow-form-label mt-2 fw-bold text-capitalize'>{`${siteOfDay[editedIndex]?.entity_type !== 'event_series' ? siteOfDay[editedIndex]?.entity_type : 'Event Series'} of the Day Description`}</label>
                                </TextEditor>
                            </div>
                        </div>
                        <ImagePreview onUpdate={() => { props.onUpdate() }} data={siteOfDay[editedIndex]} key={`ImagePreview-${editedIndex}`} section_index={siteOfDay[editedIndex].section_index} entity_index={siteOfDay[editedIndex].entity_index} section={'site_of_the_day'} imageEditorTitle={`${siteOfDay[editedIndex]?.entity_type !== 'event_series' ? siteOfDay[editedIndex]?.entity_type : 'Event Series'} of the Day - ${siteOfDay[editedIndex]?.name || personName(siteOfDay[editedIndex])}`} typeWeb={'featured@3x'} />
                    </CollapsableSection>}
                    {(editedIndex !== -1) && entityOfDay == "featured" && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            delete: { show: (siteOfDay.length > 1), onHandle: async () => { await handleDeleteSite(); await props.onUpdate(); } }
                        }}
                    >
                        <div className='mt-2 mx-5'>
                            <div className='row'>
                                <div className='col-12 m-1'>
                                    <TextEditor heading={true} data={date} onChange={(value) => setDate(value)} limit="3" >
                                        <label className='cnow-form-label fs-5  mt-2 fw-bold'>DATE</label>
                                    </TextEditor>
                                </div>
                                <div className='col-12 m-1'>
                                    <TextEditor heading={true} data={title} onChange={(value) => setTitle(value)} limit="3" >
                                        <label className='cnow-form-label fs-5  mt-2 fw-bold'>TITLE</label>
                                    </TextEditor>
                                </div>
                                <div className='col-12 m-1'>
                                    <TextEditor heading={false} data={text} onChange={(value) => setText(value)} limit="15" >
                                        <label className='cnow-form-label fs-5  mt-2 fw-bold'>WHAT HAPPENED</label>
                                    </TextEditor>
                                </div>
                                <div className='col-12 m-1'>
                                    <TextEditor heading={false} data={siteDescription} onChange={(value) => setSiteDescription(value)} limit="50" >
                                        <label className='cnow-form-label fs-5  mt-2 fw-bold'>WHY WE ARE FEATURING IT</label>
                                    </TextEditor>
                                </div>
                                <div className='col-12 m-1'>
                                    <label className='fs-5 fw-bold'>IMAGES</label>
                                    {images.map((item, index) => (
                                        <div className="d-flex" key={index}>
                                            <div className="col-1 m-1">
                                                <label className="fs-5 fw-bold">Links</label>
                                                <textarea
                                                    value={item.link}
                                                    readOnly
                                                    style={{
                                                        fontSize: "30px",
                                                        lineHeight: "6px",
                                                        fontWeight: "600",
                                                        width: "100%",
                                                        minHeight: "20px",
                                                        overflow: "hidden",
                                                        resize: "none",
                                                        color: "black",
                                                        padding: "12px"
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3 m-1">
                                                <label className="fs-5 fw-bold">Type</label>
                                                <Select
                                                    options={types}
                                                    placeholder="Select"
                                                    onChange={(value) => handleTypeChange(index, value)}
                                                    value={types.find((option) => option.value === images[index]?.name?.type) || null}
                                                />
                                            </div>
                                            <div className="col-6 m-1">
                                                <label className="fs-5 fw-bold">Name</label>
                                                {item.type ? renderNameComponent(item.type, index) : <Select options={[]} placeholder="Select Type First" isDisabled />}
                                            </div>
                                            <div style={{ marginTop: "20px", marginLeft: ".25rem" }} className='d-flex gap-2 align-items-center'>
                                                <a className={`btn btn-md btn-light`}   {...(item?.name?.type && item?.name?.vanity_url ? { href: `${process.env.REACT_APP_WEB_URL}${item.name.type}/${item.name.vanity_url}`, target: '_blank', rel: 'noopener noreferrer' } : {})}
                                                >
                                                    <Icon name='preview' size={20} />
                                                </a>
                                                <a
                                                    className="btn btn-md btn-light"
                                                    href={item?.name?.id ? `${window.location.origin}/${item.type}/${item.name.id}/editor` : '#'}
                                                    target={item?.name?.id ? '_blank' : '_self'}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Icon name="edit" size={20} />
                                                </a>

                                                <a className={`btn btn-md btn-light`} onClick={() => clearImageData(index)}
                                                    rel='noopener noreferrer'>
                                                    <Icon name='delete' size={20} />
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='col-12 m-1'>
                                    <Modal show={show} onHide={() => setShow(false)} className='modal-image-crop' backdrop="static">
                                        <Modal.Body className='h-100'>
                                            <div className='banner-head mx-5'>
                                                <div className='d-flex justify-content-between align-items-end mb-2' >
                                                    <div className='cnow-form-title cnow-text-primary text-capitalize'>{`Edit Image`}</div>
                                                    <div className='d-flex gap-2'>
                                                        <Spinner display={spinner}>
                                                            <Button size='sm' label='Save' color='danger' onClick={() => { handleSaveCropedImage() }} />
                                                            <Button size='sm' icon='close' target='_self' onClick={() => setShow(false)} />
                                                        </Spinner>
                                                    </div>
                                                </div>
                                                <hr className='cnow-hr' />
                                            </div>
                                            <div id='image_tagger' className='cnow-image-resize-container text-center mt-2'>
                                                <ReactCrop
                                                    crop={crop}
                                                    onChange={(newCrop) => setCrop(newCrop)}
                                                    onComplete={cropImage}
                                                    aspect={1 / 1.65}
                                                    keepSelection
                                                >
                                                    <ImageComponent ref={imageRef} alt="crop" src={imageEditData?.image?.startsWith("http") ? imageEditData?.image : imageEditData?.image_id} onLoad={handleLoad} crossOrigin="anonymous"
                                                        type={"featured@3x"}
                                                        className="featured-image"
                                                    />
                                                </ReactCrop>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </div >
    )
})

export default SiteOfTheDay