/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/buttons/default';
import 'react-image-crop/dist/ReactCrop.css';
import { handleApiError } from '../../helpers';
import TourInlineImageList from '../../components/tourInlineImageList';
import BannerMenu from '../tour/bannerMenu';
import Spinner from '../../components/spinner';
import ImageComponent from '../../components/image';
const _api = require('../../api')

const BannerTour = (props) => {
    const id = props.match.params.id
    const type = props.match.params.type;;
    const image = useSelector(state => state[id]);
    const images = useSelector((state) => state.images);
    const entity = useSelector(state => state[images.id])
    const [crop, setCrop] = useState({});
    const [aspectRatio, setAspect] = useState();
    const [spinner, setSpinner] = useState(false)
    const [scale, setScale] = useState({ scaleX: '', scaleY: '' });
    const [region, setRegion] = useState({ left: 0, top: 0, width: 0, height: 0 });
    const [imageList, setImageList] = useState([])

    const dispatch = useDispatch();
    const history = useHistory()

    const [selectedImage, setSelectedImage] = useState(entity?.url || (entity?.images && entity.images?.length > 0 ? entity.images[0]?.url : []))


    useEffect(() => {
        if (type === 'banner') setAspect(3.2)
        if (type === 'headshot') setAspect(1 / 2)
        if (type === 'thumb') setAspect(1.25)
        setSelectedImage(image)
    }, [type, image]);

    useEffect(() => {
        if (!image && id !== 'tour_headshot' && id !== 'tour_images') {
            _api.image.getById(id).then(image => {
                dispatch({ type: 'entity', payload: image })
            })
        }
        if (images.editType === "headshot") {
            setSpinner(true)
            let bannerImages = []
            if (entity.stops?.length) {
                for (let j in entity.stops) {
                    entity.stops[j].index = Number(j) + 1
                    if (entity.format === 2) bannerImages.push(entity.stops[j])
                    if (entity.stops[j].sites?.length) {
                        for (let i in entity.stops[j].sites) {
                            entity.stops[j].sites[i].index = entity.format === 2 ? `${Number(j) + 1}.${Number(i) + 1}` : `${Number(j) + 1}`
                            bannerImages.push(entity.stops[j].sites[i])

                        }
                    }
                }
            }
            setImageList([...bannerImages, ...entity.images])
            setSpinner(false)
        }
    }, [id])

    const handleLoad = ({ target: img }) => {
        const { offsetHeight, offsetWidth, naturalHeight, naturalWidth } = img;
        setScale({ scaleX: naturalHeight / offsetHeight, scaleY: naturalWidth / offsetWidth })
    };

    const handleSave = async () => {
        if (region?.width) {
            setSpinner(true)
            await _api.image.process({
                'id': selectedImage.id,
                'type': type,
                'region': region
            }).then(response => {
                setSpinner(false)
                toast.success('Your image has been cropped')
            }).catch(handleApiError);
        }
        if (images.editType === "headshot") {
            entity.image = selectedImage || entity.image
            entity.image_id = selectedImage ? selectedImage?.id : (entity.image_id || null)
            dispatch({ type: 'entity', payload: { ...entity, image: entity.image, image_id: entity.image_id } });
            await _api[images.entityType].update(entity).then(response => {
                setSpinner(false)
                if (!region?.width) toast.success('Your image has been cropped')
            }).catch(handleApiError);
        }
        if (images.editType === "stop") {
            let stop = images.stop[0]
            const index = entity.stops.findIndex((elem) => elem.id === stop.id)
            entity.stops[index].image_id = selectedImage ? selectedImage?.id : (entity.image_id || null)
            await _api[images.entityType].updateTourStops(images.id, entity.stops).then(response => {
                setSpinner(false)
                if (!region?.width) toast.success('Your image has been cropped')
            }).catch(handleApiError);
        }
        if (images.editType === "site") {
            let stop = images.stop[0];
            let site = images?.site[0]
            const indexStop = entity.stops.findIndex((elem) => elem.id === stop.id)
            const indexSite = stop.sites.findIndex((elem) => elem.id === site.id)
            entity.stops[indexStop].sites[indexSite].image_id = selectedImage ? selectedImage?.id : (entity.image_id || null)
            await _api[images.entityType].updateTourStops(images.id, entity.stops).then(response => {
                setSpinner(false)
                if (!region?.width) toast.success('Your image has been cropped')
            }).catch(handleApiError);
        }
    }

    const cropImage = async (crop) => {
        setRegion({
            left: parseInt(crop.x * scale.scaleX),
            top: parseInt(crop.y * scale.scaleY),
            width: parseInt(crop.width * scale.scaleY),
            height: parseInt(crop.height * scale.scaleX)
        });
    };

    const handleSelectBannerImage = async (id, entity_type) => {
        let newBannerImage = []
        if (entity_type === 'image') {
            newBannerImage = await _api.image.getById(id)
            { console.log(newBannerImage, "newBannerImage"); }
            setSelectedImage(newBannerImage)
        }
        else if (entity_type === 'site') {
            newBannerImage = await _api.site.getById(id)
            { console.log(newBannerImage, "newBannerImage"); }
            setSelectedImage(newBannerImage)

        }
    }

    const handleInlineImageList = (data) => {
        setSpinner(true)
        let imageList = []
        if (data?.length) {
            for (let i in data) {
                if (data[i]?.type === 'site_images') imageList.push(data[i]?.image)
            }
        }
        setImageList(data)
        setSpinner(false)
    }

    return (
        <div>
            <div id='top' className='fixed-top border-bottom p-3 bg-white'>
                <div className='row'>
                    <div className='col'>
                        <strong>
                            {type === 'web' && 'Image Editor'}
                            {type === 'thumb' && 'Edit Thumbnail'}
                            {type === 'banner' && 'Edit Banner'}
                            {type === 'headshot' && 'Edit Banner'}
                        </strong>
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <Spinner display={spinner}>
                                <Button size='sm' label='Save' color='danger' onClick={handleSave} />
                                <Button size='sm' icon='close' onClick={() => { images?.source ? history.push(images?.source) : history.goBack() }} />
                            </Spinner>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row cnow-tour-editor px-0 mx-0'>
                {(images.editType === 'stop' || images.editType === 'site') && <div className='col-2 bg-light container tour-banner-scrollBar'><BannerMenu tourId={entity.id} onChange={(data) => { handleInlineImageList(data) }} /></div>}
                <div className={(images?.editType === 'stop' || images.editType === 'site') ? 'col-10' : 'col-12'}>
                    <>
                        <div id='image_tagger' className='cnow-image-resize-container-tours text-center'>
                            <ReactCrop
                                crop={crop}
                                onChange={crop => setCrop(crop)}
                                onComplete={crop => cropImage(crop)}
                                aspect={aspectRatio}
                                ruleOfThirds
                            >
                                <ImageComponent alt={selectedImage?.alt_text || selectedImage?.caption} src={selectedImage?.url ? selectedImage?.url : selectedImage?.image?.url ? selectedImage?.image?.url : entity.image?.url} onLoad={handleLoad} className='cnow-image-banner' />
                            </ReactCrop>
                        </div>
                        <div>
                            <Spinner display={spinner}>
                                <TourInlineImageList
                                    onSelect={(id, entity_type) => handleSelectBannerImage(id, entity_type)}
                                    items={imageList}
                                    image={selectedImage}
                                    tourId={entity.id}
                                    bannerImageId={entity.image_id || 'tour_headshot'}
                                />
                            </Spinner>
                        </div>
                    </>
                </div>
            </div>
        </div>
    )
}
export default BannerTour;
