import React from 'react'
import { useSelector } from 'react-redux';
import ImageViewer from '../../components/viewers/image';


const RecipeImages = (props) => {
    const entity = useSelector(state => state[props?.id]);
    let currentImages = entity?.images?.filter(x => (x.type === 'image'))
    return (
        <div>
            {currentImages && currentImages.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>{"Recipe Images"}</div>
                    <div className='image-box'>
                        {currentImages.map((item, index) => {
                            return <ImageViewer key={index} url={item?.image_url} type='thumb' style={{
                                width: "75px",
                                height: "60px"
                            }} cssClass='thumb p-1' />
                        }
                        )}
                    </div>
                </>
            }
        </div>
    )
}

export default RecipeImages