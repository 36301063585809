/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Button from '../../components/buttons/default';
import LinkButton from '../../components/buttons/link';
import PreviewButton from '../../components/buttons/preview';
import Pagination from "../../components/pagination";
import ResultCount from '../../components/resultCount';
import ScrollToTop from '../../components/scrollToTop';
import Spinner from '../../components/spinner';
import Icon from '../../components/viewers/icon';
import ImageViewer from '../../components/viewers/image';
import LongTextView from '../../components/viewers/longText';

import AwardList from '../../components/table/awardList';
import DateList from '../../components/table/dateList';
import DesignationList from '../../components/table/designationList';
import LocationList from '../../components/table/locationList';
import RelatedSiteList from '../../components/table/relatedSiteList';
import ImageAlbumsList from '../../components/table/imageAlbumsList';
import TagList from '../../components/table/tagList';
import TableHeader from '../../components/tableHeader';
import { personName, TABLE_ROWS } from '../../helpers';
import PodcastList from '../../components/table/podcastList';
import PlayList from '../../components/table/playlist';
import ProfessionList from '../../components/table/professionList';
import OrganizationTypeList from '../../components/table/organizationTypeList';
import PublicationTypeList from '../../components/table/publicationTypeList';
import AffiliationList from '../../components/table/affiliationList';
import RelatedPeopleList from '../../components/table/relatedPeopleList'
import RelatedMapList from '../../components/table/relatedMapList';
import RelatedEventSeriesList from '../../components/table/relatedEventSeries';
import RelatedTourList from '../../components/table/relatedTourList';
import RelatedEventList from '../../components/table/relatedEvent';
import RelatedRecipeList from '../../components/table/relatedRecipeList';
import { useSelector } from 'react-redux';

const _api = require('../../api');
const columns = require('../../data/columns_people.json');

const People = () => {
  const history = useHistory();
  const user = useSelector(state => state.user)

  const [searchResult, setSearchResult] = useState([]);

  const [keywordValue, setKeywordValue] = useState('');
  const [keyword, setKeyword] = useState('');

  const take = 50;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(columns[0]);
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    search();
  }, [page, sort, keyword, user.id]);
  const search = async () => {
    // const from = 0;
    let sortBoolean = true

    const params = {
      "bool": {
        "must": [
          { "match": { "deleted": "false" } },
          { "match": { "created_via": "ADMIN" } },
        ]
      }
    };

    if (keyword) {
      sortBoolean = false

      params.bool.must.push({
        "multi_match": {
          "query": keyword,
          "fields": [
            "full_name^4",
            "first_name^3",
            "last_name^2",
            "name^5",
            "affiliations.related_person_name",
            "awards.award_parent_name",
            "awards.award_child_name",
            "locations.city",
            "locations.site",
            "designations.designation_name",
            "categories.category_name",
            "categories",
            "organization_types.organization_type_name",
            "publication_types.publication_type_name",
            "tags.tag_name",
            "events.event_name",
            "professions.profession_name",
            "locations.address"
          ],
          "fuzziness": "AUTO",
          "prefix_length": 2
        }
      });
    }
    const finalParams = {
      query: params
    }
    if (sortBoolean) {
      finalParams.sort = [{ "modified_date": { "order": "desc" } }]
    }

    showSpinner(true);
    _api.person.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
      setSearchResult(results?.hits);
      showSpinner(false);
    });
  };


  const deletePerson = (person) => {
    swal({
      title: `Delete People/Organization/Publication`,
      text: `Are you sure that you want to delete ${personName(person)}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => { _api.person.deleteById(person?.id).then(() => { search() }) });
  };

  const handleLive = (person) => {
    showSpinner(true)
    // let selectedSearchResult = searchResult.hits.filter((item) => item._id === personId)
    // if (selectedSearchResult?.length === 0) {
    //   showSpinner(false);
    //   return swal({
    //     title: `Something went wrong`,
    //     icon: 'error',

    //   })
    // }
    // let selectedResult = selectedSearchResult[0]._source;
    let selectedDateDescription = person?.dates?.filter((e) => e.description !== '')
    if (person.type === 'person') {
      if (
        person?.first_name &&
        person?.professions?.[0]?.profession_name &&
        (person?.description || selectedDateDescription?.length > 0) &&
        person?.image?.url
      ) {
        _api.person.updateLive(person?.id, true).then((_) => {
          search();
          swal({
            title: `Live!`,
            icon: 'success',
            text: `${person.first_name} is now Live.`,
            buttons: 'Ok',
          })
        })
      } else {
        swal({
          title: `Cannot Go Live!`,
          icon: 'error',
          text: ` Please fill the mandatory fields:
          ${person.first_name ? '' : 'Person Firstname is missing.'} 
          ${(person?.description || selectedDateDescription?.length > 0) ? '' : 'About Person is missing.'} 
          ${person?.professions && person?.professions?.[0]?.profession_name ? '' : 'Person Profession is missing.'} 
          ${person?.image?.url ? '' : 'Person Image is missing.'}`,
          buttons: 'Go back',
          dangerMode: true,
        })
      }
    } else if (person.type === 'organization') {
      if (
        person?.name &&
        person?.organization_types && person?.organization_types?.[0]?.organization_type_name &&
        (person?.description || selectedDateDescription?.length > 0) &&
        person?.image?.url
      ) {
        _api.person.updateLive(person?.id, true).then((_) => {
          search()
          swal({
            title: `Live!`,
            icon: 'success',
            text: `${person?.name} Organization is Live.`,
            buttons: 'Ok',
          })
        })
      } else {
        swal({
          title: `Cannot Go Live!`,
          icon: 'error',
          text: ` Please fill the mandatory fields:
          ${person?.name ? '' : '\n Organization name is missing.'} 
          ${(person?.description || selectedDateDescription?.length > 0) ? '' : '\n About Organization is missing.'} 
          ${person?.organization_types && person?.organization_types?.[0]?.organization_type_name ? '' : 'Organization type is missing.'}  
          ${person?.image?.url ? '' : '\n Organization Image is missing.'}`,
          buttons: 'Go back',
          dangerMode: true,
        })
      }
    } else {
      if (
        person?.name &&
        person?.publication_types && person?.publication_types?.[0]?.publication_type_name &&
        (person?.description || selectedDateDescription?.length > 0) &&
        person?.image?.url
      ) {
        _api.person.updateLive(person?.id, true).then((_) => {
          search()
          swal({
            title: `Live!`,
            icon: 'success',
            text: `${person?.name} is Live.`,
            buttons: 'Ok',
          })
        })
      } else {
        swal({
          title: `Cannot Go Live.`,
          icon: 'error',
          text: ` Please fill the mandatory fields:
          ${person?.name ? '' : 'Publication name is missing.'} 
          ${person?.publication_types && person?.publication_types?.[0]?.publication_type_name ? '' : 'Publication type is missing.'} 
          ${(person?.description || selectedDateDescription?.length > 0) ? '' : 'About Publication is missing.'}  
          ${person?.image ? '' : 'Publication Image is missing.'}`,
          buttons: 'Go back',
          dangerMode: true,
        })
      }
    }
    showSpinner(false)
  }

  const handleNotLive = (person) => {
    showSpinner(true)
    _api.person.updateLive(person?.id, false).then((_) => search())
    // let selectedSearchResult = searchResult?.hits?.filter(item => item.id === personId)
    // let person = selectedSearchResult[0]
    swal({
      title: 'Not Live!',
      icon: 'info',
      text: `${person?.type === 'person'
        ? `${person?.first_name} is Not Live.`
        : `${person?.name} is Not Live.`
        }`,
      buttons: 'Ok',
    })
    showSpinner(false)
  }

  return (
    <>
      <div className='container-fluid mt-3'>
        <div className='row g-1'>
          <div className='col'>
            <div className='input-group'>
              <span className='input-group-text border-end-0'>
                <Icon name='search' />
              </span>
              <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                onChange={(event) => { setKeywordValue(event.target.value) }}
                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue) } }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className='col-auto'>
            <div className='btn-list'>
              <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue) }} />
            </div>
          </div>
          <div className='col-auto'>
            <div className='btn-list'>
              <Button label='Add a Person' color='danger' icon='plus-white' onClick={() => history.push('/people/create')} />
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid mt-3 p-0'>
        <div className='card'>
          <div className='card-header bg-light p-2'>
            <div className='d-flex justify-content-between align-items-end'>
              <div>
                <h4 className='mb-0'>People/Organizations/Publications</h4>
                <div className='small'>
                  <ResultCount page={page} take={take} count={searchResult?.total?.value} />

                  {keyword && <span className='badge badge-search mt-1'>
                    {keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
                  </span>}
                </div>
              </div>
              <div>
                <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
              </div>
              <div>
                <div className='dropdown'>
                  <button className='btn btn-light dropdown-toggle' type='button' id="sort" data-bs-toggle='dropdown' aria-expanded='false'>
                    Sort by: {sort.name}
                  </button>
                  <ul className='dropdown-menu'>
                    {
                      columns.filter(x => x.sort === true)
                        .map((column, index) =>
                          <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                        )
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Spinner display={spinner}>
            <div className='table-responsive cnow-table'>
              <table className='table table-striped table-hover table-bordered'>
                <thead>
                  <tr className='bg-light'>
                    {columns.filter(x => x.display === true).map((column, index) =>
                      <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                    )}
                  </tr>
                </thead>
                <tbody>
                  {searchResult?.hits?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='row g-1'>
                            <div className='col-auto'>
                              <div className='form-check mt-1'>
                                <input className='form-check-input align-middle' type='checkbox' name='select_person' value={item?.id} />
                              </div>
                            </div>
                            <div className='col-auto'>
                              <div className='btn-list'>
                                <LinkButton size='sm' icon='edit' to={!item.is_participant ? `/people/${item?._source.id}/editor` : `/participant/${item?._source?.id}/editor`} />
                                <LinkButton size='sm' icon='pan' to={!item?._source.is_participant ? `/participant/${item?._source.id}/editor` : `/people/${item?._source.id}/editor`} />
                                <Button size='sm' icon='delete' onClick={() => deletePerson(item?._source)} />
                                <PreviewButton size='sm' link={!item?._source.is_participant ? `people/${item?._source?.vanity_url || item?._source?.id}` : `participant/${item?._source.id}`} />
                                <Button size='sm' icon='download' onClick={() => { }} />
                                {
                                  item?._source.live ? <Button size='live' icon='live' onClick={() => handleNotLive(item?._source)} />
                                    : <Button size='live' icon='not-live' color='danger' onClick={() => handleLive(item?._source)} />
                                }
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <ImageViewer url={item?._source?.image?.url} type='thumb' style={{
                            height: "60px"
                          }} cssClass='thumb' />
                        </td>
                        <td className='fw-bold'>
                          {personName(item?._source)}
                        </td>
                        <DesignationList data={item?._source?.designations} />
                        <td className='text-capitalize fw-bold'>{item?._source?.type}</td>
                        <td className='fw-bold fst-italic'>
                          <ProfessionList data={item?._source?.professions} />
                          <OrganizationTypeList data={item?._source?.organization_types} />
                          <PublicationTypeList data={item?._source?.publication_types} />
                        </td>
                        <AffiliationList data={item?._source.affiliations} />
                        {/* <CreditList data={item?._source?.credits} /> */}
                        <RelatedSiteList data={item?._source?.credited_sites} />
                        <RelatedSiteList data={item?._source?.related_sites} />
                        <ImageAlbumsList data={item?._source?.image_albums} />
                        <td>{item?._source.id}</td>
                        <TagList data={item?._source.tags} />
                        <td>
                          <LongTextView lines={TABLE_ROWS + 1}>{item?._source?.description}</LongTextView>
                        </td>
                        <PodcastList data={item?._source?.podcasts} />

                        <AwardList data={item?._source?.awards} />
                        <LocationList data={item?._source?.locations} />
                        <DateList data={item?._source?.dates} />
                        <td></td>
                        <PlayList data={item?._source.playlists} />
                        <RelatedEventSeriesList data={item?._source?.event_series} />
                        <RelatedRecipeList data={item?._source?.recipes} />
                        <RelatedPeopleList data={item?._source?.related_people} />
                        <RelatedEventList data={item?._source?.events} />
                        <RelatedTourList data={item?._source?.related_tours} />
                        <RelatedMapList data={item?._source?.maps} />
                        <td></td>

                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className='d-flex justify-content-between m-3'>
              <div className='cols'>
                <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
              </div>
            </div>
          </Spinner>
        </div>
      </div>
      <ScrollToTop />
    </>
  );
};

export default People;