/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
// import { saveAs } from 'file-saver'; 
import Button from '../../components/buttons/default';
import LinkButton from '../../components/buttons/link';
import PreviewButton from '../../components/buttons/preview';
import Pagination from '../../components/pagination';
import ResultCount from '../../components/resultCount';
import ScrollToTop from '../../components/scrollToTop';
import Spinner from '../../components/spinner';
import TableHeader from '../../components/tableHeader';
import Icon from '../../components/viewers/icon';
import ImageViewer from '../../components/viewers/image';
import LongTextView from '../../components/viewers/longText';
import RelatedPeopleList from '../../components/table/relatedPeopleList'
import PodcastList from '../../components/table/podcastList'
import TagList from '../../components/table/tagList';
import CategoryList from '../../components/table/categoryList';
import ThemeList from '../../components/table/themeList';
import RelatedSiteList from '../../components/table/relatedSiteList';
import RelatedMapList from '../../components/table/relatedMapList';
import PeopleList from '../../components/table/peopleList';
import RelatedEventSeriesList from '../../components/table/relatedEventSeries';
import RelatedTourList from '../../components/table/relatedTourList';
import RelatedEventList from '../../components/table/relatedEvent';
import { TABLE_ROWS, checkUserTypeAndReturnIds, getPodcastsDuration } from '../../helpers';
import { useSelector } from 'react-redux';
import ImageComponent from '../../components/image';

const columns = require('../../data/columns_playlist.json')
const _api = require('../../api')

const Playlist = (props) => {
  const history = useHistory();
  const user = useSelector(state => state.user)

  const [searchResult, setSearchResult] = useState([]);

  const [keywordValue, setKeywordValue] = useState('');
  const [keyword, setKeyword] = useState('');

  const take = 50;
  const [page, setPage] = useState(1);;
  const [sort, setSort] = useState(columns[0]);
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    search()
  }, [page, sort, keyword, user.id]);

  const search = async () => {

    let sortBoolean = true
    const params = {
      "bool": {
        "must": [
          { "match": { "deleted": "false" } },
          { "match": { "created_via": "ADMIN" } },
        ]
      }
    };

    if (keyword) {
      sortBoolean = false
      params.bool.must.push({
        "multi_match": {
          "query": keyword,
          "fields": [
            "name^3",
            "curators.person_name^2",
            "categories.category_name",
            "categories.subcategory_name",
            "tags.tag_name",
            "related_sites.site_name",
            "related_people.person_name",
            "podcasts.podcast_name",
            "people.profession.profession_name",
            "related_people.org_type.organization_name",
            "locations.address",
            "theme.theme_name",
            "maps.map_name",
            "events.event_name",
            "tours.tour_name",
          ],
          "fuzziness": 2,
          "prefix_length": 2
        }
      });
    }

    const finalParams = {
      query: params
    }
    if (sortBoolean) {
      finalParams.sort = [{ "modified_date": { "order": "desc" } }]
    }



    showSpinner(true);



    _api.playlist.searchByElastic((page - 1) * 50, take, finalParams).then(response => {
      setSearchResult(response?.hits)
      showSpinner(false)
    })
  }

  const deletePlaylist = (playlist) => {
    swal({
      title: `Delete Playlist`,
      text: `Are you sure that you want to delete ${playlist.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then((status) => { _api.playlist.deleteById(playlist?.id).then(() => { search() }) });
  };

  const handleLive = (playlist) => {
    { console.log(playlist, "playlist"); }
    showSpinner(true)
    if (
      playlist?.name &&
      playlist?.categories &&
      playlist?.categories?.[0] &&
      playlist?.description &&
      playlist?.url &&
      playlist?.podcasts &&
      playlist?.podcasts?.length
    ) {
      _api.playlist.updateLive(playlist?.id, true).then((_) => {
        search()
        swal({
          title: 'Playlist is Live!',
          icon: 'success',
          text: `${playlist?.name} is now Live!`,
          buttons: 'Ok',
        })
      })
    } else {
      swal({
        title: `${playlist.name} cannot go Live!`,
        icon: 'error',
        text: `Please fill the mandatory fields:
          ${playlist?.name ? '' : 'Title is missing.'}
          ${playlist?.categories && playlist?.categories?.[0] ? '' : 'Category is missing.'} 
          ${playlist?.description ? '' : 'Description is missing.'} 
          ${playlist?.url ? '' : 'Image is missing.'},
          ${playlist?.podcasts && playlist?.podcasts?.length && playlist?.podcasts?.length > 0 ? '' : 'Podcast is missing.'} `,
        buttons: 'Go back',
        dangerMode: true,
      })
    }
    showSpinner(false)
  }

  const handleNotLive = (playlist) => {
    showSpinner(true)

    _api.playlist.updateLive(playlist?.id, false).then((_) => {
      search()
      swal({
        title: 'Not Live!',
        icon: 'info',
        text: `${playlist?.name} is Not Live.`,
        buttons: 'Ok',
      })
    })
    showSpinner(true)
  }

  const downloadPlaylist = (item) => {
    // item.podcasts.map((item) => saveAs(item.url, item.fileName))   
  }

  const PlaylistImage = ({ url }) => {
    return (
      <div className='position-relative'>
        <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
          <ImageComponent src="/img/icons/playlist.svg" style={{ width: '100%', height: '100%' }} alt="playlist" />
        </div>
        <ImageViewer url={url} type='thumb' cssClass='thumb' />
      </div>
    )
  }
  return (
    <>
      <div className='container-fluid mt-3'>
        <div className='row g-1'>
          <div className='col'>
            <div className='input-group'>
              <span className='input-group-text border-end-0'>
                <Icon name='search' />
              </span>
              <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a playlist name, date' value={keywordValue}
                onChange={(event) => { setKeywordValue(event.target.value) }}
                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue) } }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className='col-auto'>
            <div className='btn-list d-flex gap-1'>
              <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue) }} />
              <Button label='Add a Playlist' color='danger' icon='plus-white' onClick={() => history.push('/playlist/create')} />
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid mt-3 p-0'>
        <div className='card'>
          <div className='card-header bg-light p-2'>
            <div className='d-flex justify-content-between'>
              <div>
                <h4 className='mb-0'>Playlist</h4>
                <div className='small'>
                  <ResultCount page={page} take={take} count={searchResult?.total?.value} />
                  {keyword && <span className='badge badge-search'>
                    {keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
                  </span>}
                </div>
              </div>
              <div className='cols align-self-end'>
                <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
              </div>
              <div className='align-self-end'>
                <div className='dropdown'>
                  <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                    Sort by: {sort.name}
                  </button>
                  <ul className='dropdown-menu'>
                    {columns.filter(x => x.sort === true)
                      .map((column, index) =>
                        <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Spinner display={spinner}>
            <div className='table-responsive cnow-table'>
              <table className='table table-striped table-hover table-bordered'>
                <thead>
                  <tr className='bg-light'>
                    {columns.filter(x => x.display === true).map((column, index) =>
                      <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />)}
                  </tr>
                </thead>
                <tbody>
                  {searchResult && searchResult.hits && searchResult.hits.map((item, index) => {
                    return (
                      <tr key={item.id + index}>
                        <td>
                          <div className='row g-1'>
                            <div className='col-auto'>
                              <div className='form-check mt-1'>
                                <input className='form-check-input align-middle' type='checkbox' name='select_site' value={item?._source.id} />
                              </div>
                            </div>
                            <div className='col'>
                              <div className='btn-list'>
                                <LinkButton size='sm' icon='edit' to={`/playlist/${item?._source.id}/editor`} />
                                <Button size='sm' icon='delete' onClick={() => deletePlaylist(item?._source)} />
                                <PreviewButton size='sm' link={`playlist/${item?._source.vanity_url || item?._source.id}`} />
                                <Button size='sm' icon='download' onClick={() => downloadPlaylist(item?._source)} />
                                {item?._source.live ? <Button size='live' icon='live' onClick={() => handleNotLive(item?._source)} />
                                  : <Button size='live' icon='not-live' color='danger' onClick={() => handleLive(item?._source)} />}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <PlaylistImage url={item?._source?.url} />
                        </td>
                        <td><b>{item?._source.name}</b></td>
                        <td>{item?._source?.type}</td>
                        <td>{getPodcastsDuration(item?._source?.podcasts) + ' Minutes'}</td>
                        <PeopleList data={item?._source.curators} />
                        <PodcastList data={item?._source.podcasts} />
                        <td>{item?._source.id}</td>
                        <TagList data={item?._source.tags} />
                        <td><LongTextView lines={TABLE_ROWS + 1}>{item?._source.description}</LongTextView></td>
                        <CategoryList data={item?._source.categories} />
                        <ThemeList data={item?._source.themes} />
                        <RelatedSiteList data={item?._source?.related_sites} />
                        <RelatedPeopleList data={item?._source?.related_people} />

                        <RelatedTourList data={item?._source.tours} />
                        <RelatedEventList data={item?._source?.events} />
                        <RelatedEventSeriesList data={item?._source?.event_series} />
                        <RelatedMapList data={item?._source?.maps} />
                        <td>{item?._source?.edited_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className='d-flex justify-content-between m-3'>
              <div className='cols'>
                <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
              </div>
            </div>
          </Spinner>
        </div>
      </div>

      <ScrollToTop />
    </>
  );
};

export default Playlist;