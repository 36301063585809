import React, { useState, useEffect } from 'react'
import uniqid from 'uniqid'
import { useSelector } from 'react-redux';
import ImageViewer from '../../components/viewers/image';
const PeopleImagesList = (props) => {
    const person = useSelector(state => state[props.id]);
    const [key, setKey] = useState(uniqid())
    const [banner, setBanner] = useState([])
    const [randomIndex, setRandomIndex] = useState(0)
    //refreshing keys to re-render
    useEffect(() => {
        setKey(uniqid())
        bannerImage()
        // eslint-disable-next-line
    }, [person.image?.url])

    let bannerImage = () => {
        let image = []
        if (person.sites && person.credits.sites) person.sites.forEach((elem) => image.push(elem))
        if (person.related_sites && person.credits.related_sites) person.related_sites.forEach((elem) => image.push(elem))
        if (person.publications && person.credits.publications) person.publications.forEach((elem) => image.push(elem))
        if (person.image_albums?.length && person.image_albums) person.image_albums.forEach((elem) => image.push(elem))
        if (person.additionalPhotos?.length && person.additionalPhotos) person.additionalPhotos.forEach((elem) => image.push(elem))
        if (person.images?.length && person.images) person.images.forEach((elem) => image.push(elem))
        setBanner(image)
        let index = Math.random() * image?.length
        setRandomIndex(index)
    }
    return (
        <>
            {
                <div className='position-relative' key={`person-image-${key}`}>
                    <div className='bg-dark position-absolute top-0 right-0 h-100' style={{ opacity: 0.6 }}>

                        {<ImageViewer url={banner?.length > 0 && (banner[Math.floor(randomIndex)]?.image_url || banner[Math.floor(randomIndex)]?.url)} type='banner' cssClass='cnow-image-editor-banner' />}
                    </div>
                    <div className='position-absolute top-0 left-0'>
                        <ImageViewer url={person.image?.url} style={{height: "100px"}} type={'headshot'} cssClass='cnow-image-editor-headshot-preview  cnow-person-preview-headshot' />
                    </div>
                </div>
            }
            <div style={{ paddingTop: '100px' }}>
                {
                    person.sites && person.credits.sites && person.sites?.length > 0 &&
                    <section id='sites' className='mt-3'>
                        <hr className='cnow-hr' />
                        <div className='cnow-form-title cnow-preview-title'>Sites</div>
                        {person.sites.map((item) => (<ImageViewer key={item.site_id} entityType='site' entityId={item.site_id} type='thumb' cssClass='thumb p-1' />))}
                    </section>
                }

                {
                    person.related_sites && person.credits.related_sites && person.related_sites?.length > 0 &&
                    <section id='related_sites' className='mt-3'>
                        <div className='cnow-form-title cnow-preview-title'>Related Sites</div>
                        {person.related_sites.map((item) => (<ImageViewer key={item.site_id} entityType='site' entityId={item.site_id} type='thumb' cssClass='thumb p-1' />))}
                    </section>
                }

                {
                    person.publications && person.credits.publications && person.publications?.length > 0 &&
                    <section id='publications' className='mt-3'>
                        <div className='cnow-form-title cnow-preview-title'>Publications</div>
                        {person.publications.map((item) => (<ImageViewer key={item.site_id} entityType='person' entityId={item.related_person_id} type='thumb' cssClass='thumb p-1' />))}
                    </section>
                }

                {
                    person.image_albums?.length > 0 && person.image_albums?.filter(x => x.role_name === 'Drawn By').length > 0 &&
                    <section id='drawings' className='mt-3'>
                        <div className='cnow-form-title cnow-preview-title'>Drawings</div>
                        <div className='d-flex flex-wrap'>
                        {
                           person.image_albums?.length > 0 && person.image_albums.filter(x => x.role_name === 'Drawn By').map((album, index) => {
                                let images = album.person_images || album.site_images
                                return (<div>{images?.length > 0 && images.map((image, index) => (<ImageViewer key={index} entityType='image' entityId={image.image_id} type='thumb' cssClass='thumb p-1' />))}</div>)
                            })
                        }</div>
                    </section>
                }

                {
                    person.images?.length > 0 && person.images.filter(x => x.type === 'document').length > 0 &&
                    <section className='mt-3'>
                        <div className='cnow-form-title cnow-preview-title'>Other Drawings</div>
                        {person.images?.filter(x => x.type === 'document').map((item) => (<ImageViewer key={item.id} entityType='image' entityId={item.image_id} type='thumb' cssClass='thumb p-1' />))}
                    </section>

                }

                {
                    person.additionalPhotos?.length > 0 &&
                    <section id='additional photographs' className='mt-3'>
                        <div className='cnow-form-title cnow-preview-title'>Additional Photographs</div>
                        <div>
                            {person.additionalPhotos.map((image, index) => (
                                <ImageViewer key={index} entityId={image.image_id} entityType='image' type='thumb' cssClass='thumb p-1' />
                            ))}
                        </div>
                    </section>

                }

                {
                    person.images?.length > 0 && person.images.filter(x => x.type === 'image').length > 0 &&
                    <section className='mt-3'>
                        <div className='cnow-form-title cnow-preview-title'>Other Photos</div>
                        {person.images?.filter(x => x.type === 'image').map((item) => (<ImageViewer key={item.id} entityType='image' entityId={item.image_id} type='thumb' cssClass='thumb p-1' />))}
                    </section>
                }
            </div>
        </>
    )
}

export default PeopleImagesList