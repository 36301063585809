/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";

import Button from "../../../components/buttons/default";
import PreviewButton from "../../../components/buttons/preview";
import Pagination from "../../../components/pagination";
import ResultCount from "../../../components/resultCount";
import ScrollToTop from "../../../components/scrollToTop";
import Spinner from "../../../components/spinner";
import CreditList from "../../../components/table/creditList";
import LocationList from "../../../components/table/locationList";
import RelatedEntityList from "../../../components/table/relatedEntityList";
import TagList from "../../../components/table/tagList";
import TableHeader from "../../../components/tableHeader";
import Icon from "../../../components/viewers/icon";
import ImageViewer from "../../../components/viewers/image";
import LongTextView from "../../../components/viewers/longText";
import PodcastUpload from "../../podcast/upload";
import { dateString, TABLE_ROWS } from "../../../helpers";

const columns = require("../../../data/columns_podcast.json");
const _api = require("../../../api");

const PodcastTable = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchResult, setSearchResult] = useState([]);
  const [uploader, showUploader] = useState(false);
  const [keywordValue, setKeywordValue] = useState("");
  const [keyword, setKeyword] = useState("");

  const take = 50;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(columns[0]);

  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    search();
  }, [page, sort, keyword, user.id]);

  const search = async () => {
    let sortBoolean = true;
    const params = {
      bool: {
        must: [{ match: { deleted: "false" } }],
        must_not: [
          {
            match: {
              created_via: "ADMIN"
            }
          }
        ]
      }
    };

    if (keyword) {
      sortBoolean = false;
      params.bool.must.push({
        multi_match: {
          query: keyword,
          fields: [
            "name^3",
            "type",
            "credits.person_name^2",
            "tags.tag_name",
            "related_item.name"
          ],
          fuzziness: 2,
          prefix_length: 2
        }
      });
    }
    const finalParams = {
      query: params
    };
    if (sortBoolean) {
      finalParams.sort = [{ modified_date: { order: "desc" } }];
    }

    showSpinner(true);

    _api.podcast
      .searchByElastic((page - 1) * 50, take, finalParams)
      .then((results) => {
        setSearchResult(results?.hits);
        showSpinner(false);
        // dispatch({ type: 'podcasts', payload: [] })
      });
  };

  const deletePodcast = (podcast) => {
    swal({
      title: `Delete Podcast`,
      text: `Are you sure that you want to delete ${podcast.index_name}?`,
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true
    }).then((status) => {
      _api.podcast.deleteById(podcast?.id).then(() => {
        search();
      });
    });
  };

  const updateLive = (podcast, live) => {
    showSpinner(true);

    if (live === true) {
      _api.podcast.updateLive(podcast?.id, live).then((_) => search());
      swal({
        title: "Podcast is Live!",
        icon: "success",
        text: `${podcast?.name} is now Live!`,
        buttons: "Ok"
      });
    } else {
      _api.podcast.updateLive(podcast?.id, live).then((_) => search());
      swal({
        title: "Not Live!",
        icon: "info",
        text: `${podcast?.name} is Not Live.`,
        buttons: "Ok"
      });
    }
    showSpinner(false);
  };

  const handleEdit = (route) => {
    history.push({ pathname: route });
    dispatch({
      type: "podcasts",
      payload: { entityPodcasts: [], source: location.pathname }
    });
  };

  const handleOpenUploader = () => {
    dispatch({
      type: "podcasts",
      payload: { entityPodcasts: [], source: location.pathname }
    });
    showUploader(true);
  };
  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row g-1">
          <div className="col">
            <div className="input-group">
              <span className="input-group-text border-end-0">
                <Icon name="search" />
              </span>
              <input
                className="form-control bg-light border-start-0"
                type="text"
                placeholder="Search by a person, a category, a site name, date"
                value={keywordValue}
                onChange={(event) => {
                  setKeywordValue(event.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setPage(1);
                    setKeyword(keywordValue);
                  }
                }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="d-flex gap-1 btn-list">
              <Button
                label="Search"
                onClick={() => {
                  setPage(1);
                  setKeyword(keywordValue);
                }}
              />
              <Button
                label="Add a Podcast"
                color="danger"
                icon="plus-white"
                onClick={() => handleOpenUploader()}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-3 p-0">
        <div className="card">
          <div className="card-header bg-light p-2">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mb-0">Podcasts</h4>
                <div className="small">
                  <ResultCount
                    page={page}
                    take={take}
                    count={searchResult?.total?.value}
                  />

                  {keyword && (
                    <span className="badge badge-search">
                      {keyword}{" "}
                      <Icon
                        name="close"
                        size={10}
                        left={4}
                        cursor="pointer"
                        onClick={() => {
                          setKeyword("");
                          setKeywordValue("");
                        }}
                      />
                    </span>
                  )}
                </div>
              </div>
              <div className="cols align-self-end">
                <Pagination
                  page={page}
                  take={take}
                  count={searchResult?.total?.value}
                  setPage={setPage}
                />
              </div>
              <div className="align-self-end">
                <div className="dropdown">
                  <button
                    className="btn btn-light dropdown-toggle"
                    type="button"
                    id="sort"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Sort by: {sort.name}
                  </button>
                  <ul className="dropdown-menu">
                    {columns
                      .filter((x) => x.sort === true)
                      .map((column, index) => (
                        <button
                          key={index}
                          className="dropdown-item"
                          onClick={() => setSort(column)}
                        >
                          {column.name}
                        </button>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Spinner display={spinner}>
            <div className="table-responsive cnow-table">
              <table className="table table-striped table-hover table-bordered">
                <thead>
                  <tr className="bg-light">
                    {columns
                      .filter((x) => x.display === true)
                      .map((column, index) => (
                        <TableHeader
                          key={index}
                          column={column}
                          onSort={(column) => setSort(column)}
                        />
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {searchResult?.hits?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="row g-1">
                            <div className="col-auto">
                              <div className="form-check mt-1">
                                <input
                                  className="form-check-input align-middle"
                                  type="checkbox"
                                  name="select_site"
                                  value={item?._source.id}
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="btn-list">
                                <Button
                                  size="sm"
                                  icon="edit"
                                  onClick={() => {
                                    handleEdit(
                                      `/podcast/${item?._source.id}/editor`
                                    );
                                  }}
                                />
                                <Button
                                  size="sm"
                                  icon="delete"
                                  onClick={() => deletePodcast(item?._source)}
                                />
                                <PreviewButton
                                  size="sm"
                                  link={`podcast/${item?._source.id}`}
                                />
                                <Button
                                  size="live"
                                  icon={
                                    item?._source.live === true
                                      ? "live"
                                      : "not-live"
                                  }
                                  color={
                                    item?._source.live === true ? "" : "danger"
                                  }
                                  onClick={() =>
                                    updateLive(
                                      item?._source,
                                      item?._source.live === true ? false : true
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <img
                            src={
                              item?._source?.image?.url ||
                              "/img/thumb_podcast.png"
                            }
                            style={{
                              width: "75px",
                              height: "60px"
                            }}
                          />
                          {/* <ImageViewer
                            entityType={"podcast"}
                            entityId={item?._source?.id}
                            type="thumb@2x"
                            cssClass="thumb"
                          /> */}
                        </td>
                        <td className="fw-bold">
                          <LongTextView lines={TABLE_ROWS + 1}>
                            {item?._source.name || item?._source.caption}
                          </LongTextView>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Icon
                            name={`podcast_${item?._source.type}`}
                            size={16}
                          />
                        </td>
                        <td>{item?._source.duration}</td>
                        <CreditList data={item?._source.credits} />
                        <LocationList data={item?._source?.related_item} />
                        <td>{item?._source.id}</td>
                        <td>{dateString(item?._source.date_uploaded)}</td>
                        <TagList data={item?._source.tags} />
                        <td>
                          <LongTextView lines={TABLE_ROWS + 1}>
                            {item?._source.description}
                          </LongTextView>
                        </td>
                        <RelatedEntityList
                          data={item?._source?.related_item?.filter(
                            (elem, index) => elem.source === "playlist"
                          )}
                          type="playlist"
                        />
                        <RelatedEntityList
                          data={item?._source?.related_item?.filter(
                            (elem, index) => elem.source === "site"
                          )}
                          type="site"
                        />
                        <RelatedEntityList
                          data={item?._source?.related_item?.filter(
                            (elem, index) => elem.source === "person"
                          )}
                          type="people"
                        />
                        <RelatedEntityList
                          data={item?._source?.related_item?.filter(
                            (elem, index) => elem.source === "person"
                          )}
                          type="tour"
                        />
                        <RelatedEntityList
                          data={item?._source?.related_item?.filter(
                            (elem, index) => elem.source === "person"
                          )}
                          type="events"
                        />
                        <RelatedEntityList
                          data={item?._source?.related_item?.filter(
                            (elem, index) => elem.source === "person"
                          )}
                          type="event_series"
                        />

                        <td></td>
                        <td></td>
                        <td></td>
                        <RelatedEntityList
                          data={item?._source?.related_item?.filter(
                            (elem, index) => elem.source === "map"
                          )}
                          type="map"
                        />
                        <td>{item?._source?.edited_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-between m-3">
              <div className="cols">
                <Pagination
                  page={page}
                  take={take}
                  count={searchResult?.total?.value}
                  setPage={setPage}
                />
              </div>
            </div>
          </Spinner>
        </div>
      </div>
      {uploader && (
        <PodcastUpload
          show={uploader}
          onHide={() => {
            // dispatch({ type: 'podcasts', payload: [] })
            showUploader(false);
          }}
        />
      )}
      <ScrollToTop />
    </>
  );
};

export default PodcastTable;
