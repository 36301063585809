import axios from 'axios';
import { toast } from 'react-toastify';

function ApiCall(method, url, params) {
    const errorMessage = 'Oops! There was an error trying to process your request. Please try again or contact admin.';
    const data = JSON.parse(localStorage.getItem('cnow-cms'));
    const token = data?.user?.token;

    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    const requestConfig = { method, url, headers, data: params };

    return new Promise((resolve, reject) => {
        axios(requestConfig)
            .then((res) => resolve(res.data))
            .catch((error) => {
                toast.error(error.response?.data?.message || errorMessage);
                reject(null);
                if (!token) {
                    localStorage.clear();
                    window.location.href = `/`;
                }
            });
    });
}

export { ApiCall };
