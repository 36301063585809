import React from 'react';
import AsyncSelect from 'react-select/async';
import { checkUserTypeAndReturnIds, customSelectStyle } from '../../helpers';
import NoOptionsMessage from './NoOptionsMessage';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const _api = require('../../api')

const SelectRecipe = (props) => {
	const user = useSelector(state => state.user)
	let selected = props.value?.id ? { 'value': props.value.id, 'label': props.value.name } : {};

	const items = async (keyword, callback) => {
		let items = [];
		if (keyword) {
			const params = {
				"bool": {
					"must": [
						{ "match": { "deleted": "false" } },
						{ "match": { "created_via": "ADMIN" } },

						{
							"multi_match": {
								"query": keyword,
								"fields": [
									"name",
								],
								"fuzziness": 2,
								"prefix_length": 2
							}
						}
					]
				}
			};
			if (props?.type_id) {
				params.bool.must[3] = {
					"bool": {
						"should": [
							{
								"match": {
									"type_id": props?.type_id
								}
							}
						]
					}
				}
			}
			const finalParams = {
				query: params
			}

			let response = await _api.recipe.searchByElastic(0, 50, finalParams);
			response.hits.hits.forEach(data => items.push({
				'value': data._source?.id,
				'label': data._source?.name,
				'image': data._source?.image?.url,
				'images': data._source?.images,
				'image_id': data._source?.image?.id,
				vanity_url: data._source?.vanity_url,
				'type': 'recipe'
			}));
		}
		callback(items)
		return items;
	}

	const handleChange = (e) => {
		const value = { 'id': e.value, 'name': e.label, 'image': e.image, 'type': 'recipe', 'images': e.images, 'image_id': e.image_id, vanity_url: e.vanity_url };
		props.onChange(value);
	}

	const debouncedSuggestions = debounce((keyword, callBack) => {
		items(keyword, callBack)
	}, 500)

	return (
		<AsyncSelect cacheOptions defaultOptions
			styles={customSelectStyle}
			loadOptions={debouncedSuggestions}
			value={selected}
			onChange={handleChange}
			components={{ NoOptionsMessage }}
		/>
	);
};

export default SelectRecipe;