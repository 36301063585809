/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'

import Dragula from 'react-dragula'
import uniqid from 'uniqid'

import Button from '../../components/buttons/default'
import PageTitle from '../../components/pageTitle'
import { customSelectStyle, dateString, handleReorder, getNewItemIndex } from '../../helpers'
import { debounce } from 'lodash';
const _api = require('../../api')

const SiteCategory = (props) => {
  const dispatch = useDispatch()
  const site = useSelector(state => state[props.id])

  // Dragula Start
  const [dragula, setDragula] = useState(uniqid())
  const dragulaRef = useRef()
  const dragulaContainer = 'dragula-container'
  const [showcategory, setShowCategory] = useState(site?.show_category)
  useEffect(() => {
    let containers = []
    containers.push(document.getElementById(dragulaContainer))
    if (site.dates && site.dates.length > 0) site.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
    dragulaRef.current = Dragula(containers, {})
    dragulaRef.current.on('drop', () => {
      const items = handleReorder(site.categories, site.dates, dragulaContainer)
      setDragula(uniqid())
      dispatch({ type: 'entity', payload: { ...site, categories: items } })
    })
  }, [dragula])
  // Dragula End

  const searchCategory = async (parent, keyword, callback) => {
    let items = []
    let response = await _api.global_list.getAll('site_category', keyword, parent)
    response.forEach(data => items.push({ 'value': data.id, 'label': data.name, 'has_children': data.has_children }))
    callback(items)
    return items
  }

  const debouncedSuggestions = debounce((parent, keyword, callBack) => {
    searchCategory(parent, keyword, callBack)
  }, 500)

  const addCategory = (date_id) => {
    if (!site.categories) site.categories = []
    let index = getNewItemIndex(site.categories, site.dates, date_id)
    // Insert new category at the appropriate index
    site.categories.splice(index, 0, {
      'id': `temp.${uniqid()}`,
      'site_id': site.id,
      'date_id': date_id,
      'category_id': '',
      'category_name': '',
      'subcategory_id': '',
      'subcategory_name': '',
      'program_id': '',
      'program_name': '',
      'sort': index
    })

    // Update sort order
    site.categories.forEach((item, index) => item.sort = index + 1)
    setDragula(uniqid())
    // Update store
    dispatch({ type: 'entity', payload: { ...site, categories: site.categories } })
  }

  const removeCategory = async (index) => {
    if (site.categories[index]) {
      if (!site.categories[index].id.startsWith('temp.'))
        await _api.site.deleteCategory(site.categories[index].id)

      site.categories.splice(index, 1)
      site.categories.forEach((item, index) => item.sort = index + 1)
      dispatch({ type: 'entity', payload: { ...site, categories: site.categories } })
    }
  }

  const handleCategoryChange = (index, event) => {
    site.categories[index].category_id = event.value
    site.categories[index].category_name = event.label

    site.categories[index].subcategory_id = null
    site.categories[index].subcategory_name = null
    site.categories[index].program_id = null
    site.categories[index].program_name = null
    site.categories[index].category_has_children = event.has_children

    dispatch({ type: 'entity', payload: { ...site, categories: site.categories } })
  }

  const handleSubCategoryChange = (index, event) => {
    site.categories[index].subcategory_id = event.value
    site.categories[index].subcategory_name = event.label

    site.categories[index].program_id = null
    site.categories[index].program_name = null
    site.categories[index].subcategory_has_children = event.has_children

    dispatch({ type: 'entity', payload: { ...site, categories: site.categories } })
  }

  const handleProgramChange = (index, event) => {
    site.categories[index].program_id = event.value
    site.categories[index].program_name = event.label

    dispatch({ type: 'entity', payload: { ...site, categories: site.categories } })
  }

  const handleDateApplicable = (dateId, checked) => {
    if (checked) {
      addCategory(dateId)
    } else {
      site.categories = site.categories.filter(function (item) { return (item.date_id === null || item.date_id !== dateId) })
      site.categories.forEach((item, index) => item.sort = index + 1)

      dispatch({ type: 'entity', payload: { ...site, categories: site.categories } })
    }
  }

  const getTitle = (category) => {
    return category.category_name === 'Infrastructure' || category.category_name === 'Planning' || category.category_name === 'Cityscape' || category.category_name === 'Public Art'
  }

  const SUB_CAT = {
    'Architecture': 'Program *',
    'Public Art': 'Program *',
    'Infrastructure': 'Type *',
    'Planning': 'Type *',
    'Landscape': 'Type *',
    'Events and Stories': 'Type *',
    'Artifact': 'Sub Category *',
    'Cityscape': 'Program *',
  }

  const handleInputChange = ({ target: { value } }) => {
    setShowCategory(!showcategory)
    site.show_category = !showcategory
    dispatch({ type: 'entity', payload: { ...site } })
  }

  return (
    <>
      {
        site && <>

          <PageTitle title={site.name} />

          <div id='header' >
            <hr className='cnow-hr' />
            <div className='row'>
              <div className='col'>
                <div className='form-group'>
                  <div className='cnow-form-title fw-bold'>Primary Category (Current)*</div>
                </div>
              </div>
              <div className='col'>
                <input className='form-check-input' type='checkbox' value={site?.show_category} checked={showcategory} onChange={handleInputChange} />
                <label className={`small form-check-label ms-2 `}>Do not display category on the frontend</label>
              </div>
              <div className='col-auto'>
                <Button size='sm' icon='plus' label='Add a Category' onClick={() => addCategory(null)} />
              </div>
            </div>
          </div>
          <div id={dragulaContainer} key={dragula} className='dragula-container'>
            {
              site.categories?.filter(x => x.date_id === null).map((category, index) => {
                const selectedCategory = { 'value': category.category_id, 'label': category.category_name }
                const selectedSubCategory = { 'value': category.subcategory_id, 'label': category.subcategory_name }
                const selectedProgram = { 'value': category.program_id, 'label': category.program_name }

                return (
                  <div key={index} id={category.id} className={`${category.id} dragula-item`} >
                    <div className='row g-1 mb-3'>
                      {
                        site.categories.length > 1 &&
                        <div className='col-auto'>
                          <label className='cnow-form-label fw-bold'>Order</label>
                          <input type='text' className='prime form-control form-control-sort' name={category.category_name} id={category.category_id} disabled placeholder={category.sort} data-id={category.category_id} autoComplete='off' />
                          <input className='cnow-data' id='primeClass' name={category.date} hidden></input>
                        </div>
                      }
                      <div className='col'>
                        <div className='w-100'>
                          <label className='cnow-form-label fw-bold'>{index === 0 ? `Primary Main Category *` : 'Main Category *'}</label>
                          <AsyncSelect defaultOptions styles={customSelectStyle} loadOptions={(keyword, callback) => debouncedSuggestions('', keyword, callback)}
                            value={selectedCategory} onChange={(e) => handleCategoryChange(index, e)} />
                        </div>
                        {
                          (category?.subcategory_id || category.category_has_children) && <div className='w-100 mt-2'>
                            <label className='cnow-form-label fw-bold'>{getTitle(category) ? 'Sub Category *' : SUB_CAT[category.category_name]}</label>
                            <AsyncSelect key={category.category_id} defaultOptions styles={customSelectStyle} loadOptions={(keyword, callback) => debouncedSuggestions(category.category_id, keyword, callback)}
                              value={selectedSubCategory} onChange={(e) => handleSubCategoryChange(index, e)} />
                            <input className='sub-cnowp' id={category.subcategory_name} hidden></input>
                          </div>
                        }
                        {
                          (category?.program_id || category?.subcategory_has_children) && <div className='w-100 mt-2'>
                            <label className='cnow-form-label fw-bold'>{SUB_CAT[category.category_name]}</label>
                            <AsyncSelect key={category.subcategory_id} defaultOptions styles={customSelectStyle} loadOptions={(keyword, callback) => debouncedSuggestions(category.subcategory_id, keyword, callback)}
                              value={selectedProgram} onChange={(e) => handleProgramChange(index, e)} />
                          </div>
                        }
                      </div>
                      <div className='col-auto'>
                        <label className='cnow-form-label'></label>
                        <div className='text-end'>
                          <Button icon='delete' onClick={() => removeCategory(index)} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>

          {
            site.dates?.length > 0 &&
            <>
              <hr className='cnow-hr' />
              <div className='cnow-form-title fw-bold'>Categories, By Dates </div>
              {
                site.dates?.map((date, dateIndex) => {
                  let isApplicable = site.categories?.findIndex(x => x.date_id === date.id) >= 0
                  return (
                    <div className='mb-2' key={dateIndex}>
                      <div className='row'>
                        <div className='col-3'>
                          <label className='cnow-form-subtitle'>{date.event_type_name} - {dateString(date)}</label>
                        </div>
                        <div className='col-4'>
                          <div className='form-check' key={date.id}>
                            <input className='form-check-input' name='date_categories' type='checkbox' checked={isApplicable} onChange={(e) => handleDateApplicable(date.id, e.target.checked)} />
                            <label className='small'>If different</label>
                          </div>
                        </div>
                        <div className='col-5 text-end'>
                          {
                            isApplicable && <Button size='sm' icon='plus' label='Add a Category' onClick={() => addCategory(date.id)} />
                          }
                        </div>
                      </div>
                      <div id={`${dragulaContainer}-${date.id}`} key={`${dragula}${date.id}`} className='dragula-container'>
                        {
                          site.categories?.filter(x => x.date_id === date.id).map((category, index) => {
                            const catIndex = site.categories.findIndex(x => x.id === category.id)
                            const selectedCategory = { 'value': category.category_id, 'label': category.category_name }
                            const selectedSubCategory = { 'value': category.subcategory_id, 'label': category.subcategory_name }
                            const selectedProgram = { 'value': category.program_id, 'label': category.program_name }

                            return (
                              <div key={index}>
                                <div id={category.id} className={`row g-1 mb-3 dragula-item`}>
                                  {
                                    site.categories.length > 1 &&
                                    <div className='col-auto'>
                                      <label className='cnow-form-label fw-bold'>Order</label>
                                      <input type='text' typename={category.date} className='date form-control form-control-sort' id={category.category_id} name={category.category_name} disabled placeholder={category.sort} data-id={category.category_id} autoComplete='off' />
                                      <input className='cnow-data1' id={date.id} name={category.date} hidden></input>
                                    </div>
                                  }
                                  <div className='col'>
                                    <div className='w-100'>
                                      <label className='cnow-form-label fw-bold'>Main Category *</label>
                                      <AsyncSelect defaultOptions styles={customSelectStyle} loadOptions={(keyword, callback) => debouncedSuggestions('', keyword, callback)}
                                        value={selectedCategory} onChange={(e) => handleCategoryChange(catIndex, e)} />
                                    </div>
                                    {
                                      (category.subcategory_id || category.category_has_children) && <div className='w-100 mt-2'>
                                        <label className='cnow-form-label fw-bold'>{getTitle(category) ? 'Sub Category *' : SUB_CAT[category.category_name]}</label>
                                        <AsyncSelect key={category.category_id} defaultOptions styles={customSelectStyle} loadOptions={(keyword, callBack) => debouncedSuggestions(category.category_id, keyword, callBack)}
                                          value={selectedSubCategory} onChange={(e) => handleSubCategoryChange(catIndex, e)} />
                                        <input className='sub-cnowD' id={category.subcategory_name} hidden></input>
                                      </div>
                                    }
                                    {
                                      (category?.program_id || category?.subcategory_has_children) && <div className='w-100 mt-2'>
                                        <label className='cnow-form-label fw-bold'>{SUB_CAT[category.category_name]}</label>
                                        <AsyncSelect key={category.subcategory_id} defaultOptions styles={customSelectStyle} loadOptions={(keyword, callback) => debouncedSuggestions(category.subcategory_id, keyword, callback)}
                                          value={selectedProgram} onChange={(e) => handleProgramChange(catIndex, e)} />
                                      </div>
                                    }
                                  </div>
                                  <div className='col-auto'>
                                    <label className='cnow-form-label'></label>
                                    <div className='text-end'>
                                      <Button icon='delete' onClick={() => removeCategory(catIndex)} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </>
          }
        </>
      }
    </>
  )
}

export default SiteCategory