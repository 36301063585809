import moment from 'moment';

export const getElasticQueryBasedOnType = (
  type,
  keyword,
  isDeleted = false
) => {
  // Base query structure
  const baseQuery = {
    bool: {
      must: [
        // { match: { live: 'true' } },
        { match: { deleted: `${isDeleted}` } },
        { match: { created_via: 'ADMIN' } },
        {
          multi_match: {
            query: keyword,
            fields: [], // Empty by default; will be filled based on the type
            fuzziness: 2,
            prefix_length: 2,
          },
        },
      ],
    },
  };
  if (keyword) {
    // Extend fields or filters based on the type
    switch (type) {
      case 'site':
        baseQuery.bool.must[3].multi_match.fields = [
          'name^12',
          'alias^6',
          'people.person_name^9',
          'people.person_first_name^3',
          'people.person_last_name^3',
          'locations.name',
          'locations.city',
          'locations.state',
          'locations.country',
          'categories.category_name',
          'categories.subcategory_name',
          'tags.tag_name',
          'related_sites.related_site_name',
          'related_people.related_person_name',
          'podcasts.podcast_name',
          'people.role_name',
          'people.profession.profession_name',
          'related_people.org_type.organization_name',
          'locations.address',
          'theme.theme_name',
        ];
        break;

      case 'podcast':
        baseQuery.bool.must[3].multi_match.fields = [
          'name^3',
          'type',
          'credits.person_name^2',
          'tags.tag_name',
          'related_item.name',
        ];
        break;

      case 'tour':
        baseQuery.bool.must[3].multi_match.fields = [
          'name^6',
          'tour_leader.person_name^2',
          'created_by.person_name^2',
          'people.person_name^2',
          'partner_org.person_name^2',
          'alias',
          'type',
          'stops.location.name',
          'stops.location.city',
          'stops.location.state',
          'stops.location.country',
          'categories.category_name',
          'categories.subcategory_name',
          'tags.tag_name',
          'related_sites.related_site_name',
          'related_sites.site_name',
          'related_people.person_name',
          'podcasts.podcast_name',
          'stops.location.address',
          'theme.theme_name',
        ];
        break;

      case 'self-guided-tour':
        baseQuery.bool.must = [
          { match: { live: 'true' } },
          { match: { deleted: 'false' } },
          { match: { created_via: 'ADMIN' } },
          { match_all: {} }, // Match all documents
        ];
        break;

      case 'recipe-all':
        baseQuery.bool.must = [
          { match: { live: 'true' } },
          { match: { deleted: 'false' } },
          { match: { created_via: 'ADMIN' } },
          { match_all: {} }, // Match all documents
        ];
        break;

      case 'person':
        baseQuery.bool.must[3].multi_match.fields = [
          'full_name^4',
          'first_name^3',
          'last_name^2',
          'name^5',
          'affiliations.related_person_name',
          'awards.award_parent_name',
          'awards.award_child_name',
          'locations.city',
          'locations.site',
          'designations.designation_name',
          'categories.category_name',
          'categories',
          'organization_types.organization_type_name',
          'publication_types.publication_type_name',
          'tags.tag_name',
          'events.event_name',
          'professions.profession_name',
          'locations.address',
        ];
        baseQuery.bool.must[3].multi_match.fuzziness = 'AUTO'; // Set fuzziness to AUTO
        break;

      case 'autocomplete':
        baseQuery.bool.must = [
          {
            multi_match: {
              query: keyword,
              fields: ['name^3'],
              fuzziness: 2,
              prefix_length: 2,
            },
          },
        ];
        break;

      case 'playlist':
        baseQuery.bool.must[3].multi_match.fields = [
          'name^3',
          'curators.person_name^2',
          'categories.category_name',
          'categories.subcategory_name',
          'tags.tag_name',
          'related_sites.site_name',
          'related_people.person_name',
          'podcasts.podcast_name',
          'people.profession.profession_name',
          'related_people.org_type.organization_name',
          'locations.address',
          'theme.theme_name',
          'maps.map_name',
          'events.event_name',
          'tours.tour_name',
        ];
        break;

      case 'map':
        baseQuery.bool.must[3].multi_match.fields = [
          'name^3',
          'people.person_name^2',
          'dates.event_type_name',
          'locations.name',
          'locations.city',
          'locations.state',
          'locations.country',
          'categories.category_name',
          'categories.subcategory_name',
          'tags.tag_name',
          'related_sites.related_site_name',
          'related_people.related_person_name',
          'podcasts.podcast_name',
          'people.role_name',
          'people.profession.profession_name',
          'related_people.org_type.organization_name',
          'locations.address',
          'theme.theme_name',
          'maps.map_name',
          'events.event_name',
          'tours.tour_name',
          'event_series.event_series_name',
        ];
        break;

      case 'event':
        baseQuery.bool.must[3].multi_match.fields = [
          'name^5',
          'participants.person_name',
          'alias',
          'participants.person_first_name',
          'participants.person_last_name',
          'hosts.person_name',
          'locations.name',
          'locations.city',
          'locations.state',
          'locations.country',
          'categories.category_name',
          'categories.subcategory_name',
          'types.type_name',
          'tags.tag_name',
          'related_sites.related_site_name',
          'related_people.related_person_name',
          'related_people.person_name',
          'podcasts.podcast_name',
          'participants.role_name',
          'people.profession.profession_name',
          'related_people.org_type.organization_name',
          'locations.address',
        ];
        baseQuery.bool.must[3].multi_match.fuzziness = 'AUTO'; // Set fuzziness to AUTO
        baseQuery.bool.must.push({
          range: {
            'occurring_dates.occurrence': {
              gte: moment().unix(),
            },
          },
        });
        break;

      case 'event_series':
        baseQuery.bool.must[3].multi_match.fields = [
          'name^3',
          'host.person_name^2',
          'locations.name',
          'locations.city',
          'locations.state',
          'locations.country',
          'categories.category_name',
          'categories.subcategory_name',
          'tag.tag_name',
          'related_sites.site_name',
          'related_people.person_name',
          'podcasts.podcast_name',
          'podcasts.podcast_type',
          'people.role_name',
          'people.profession.profession_name',
          'related_people.org_type.organization_name',
          'locations.address',
          'themes.theme_name',
          'types.type_name',
          'featured.event_name',
          'related_event.event_name',
        ];
        break;

      case 'recipe':
        baseQuery.bool.must[3].multi_match.fields = [
          'name^3',
          'people.person_name^2',
          'locations.name',
          'location',
          'related_events.categories.category_name',
          'related_event_series.categories.category_name',
          'related_sites.categories.category_name',
          'related_sites.tags.tag_name',
          'tags.tag_name',
          'podcasts.podcast_name',
          'podcasts.podcast_type',
          'type_name',
          'people.role_name',
        ];
        break;

      default:
        // If no specific fields, keep the base query
        break;
    }

}
return baseQuery;
};
