import React, { useEffect, useState } from "react";
import { groupBy, uniqBy, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import moment from "moment";
import Spinner from "../spinner";
import Button from "../buttons/default";
import ImageViewer from "../viewers/image";
import ImageComponent from "../image";
const LegalHistory = ({ id, type }) => {
    const [legalizedData, setLegalizedData] = useState([]);
    const [spinner, setSpinner] = useState(false)
    const entity = useSelector(state => state[id]);
    // eslint-disable-next-line
    useEffect(() => { handleLegalizedInfo() }, []);

    const handleLegalizedInfo = () => {
        setSpinner(true)
        const uniqueImages = uniqBy(entity.images, 'image_id').filter((elem) => elem.is_legal && elem.type !== 'then_now');
        const uniquePodcasts = uniqBy(entity.podcasts, 'podcast_id').filter((elem) => elem.is_legal)
        const imagePodcastdata = [...uniqueImages, ...uniquePodcasts]
        const dataSet = imagePodcastdata?.length > 0 ? groupBy(imagePodcastdata, (elem) => elem.legal_modified_date ? elem.legal_modified_date.split('T')[0] : '') : []
        const data = Object.keys(dataSet).map((key) => {
            return {
                [key]: dataSet[key],
                id: key
            }
        }
        );
        setLegalizedData(data)
        setSpinner(false)
    }
    return (
        <>
            <Spinner display={spinner}>
                <hr className="cnow-hr" />
                <div className='cnow-form-title'>{'LEGAL HISTORY'}</div>
                {legalizedData?.length > 0 && orderBy(legalizedData, [(obj) => (obj.id ? new Date(obj.id) : null)], ['asc']).map((elem, index) => {
                    return (
                        <>
                            <LegalHistoryDate date={elem.id} data={elem[elem.id]} index={index} id={entity.id} type={type} />
                        </>
                    )
                })}
            </Spinner>
        </>
    )
}

export default LegalHistory


const LegalHistoryDate = ({ date, data, index, id, type }) => {
    const [more, showMore] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [signature, setSignature] = useState('')
    const history = useHistory()
    const images = data?.filter((elem) => elem.image_id)
    const podcasts = data?.filter((elem) => elem.podcast_id)
    const handleDigitalSignature = (signature) => {
        setSignature(signature)
        setShowModal(true)
    }

    const handleDocumentUpload = (url) => {
        window.open(url)
    }

    return (
        <>
            <div className='table table-borderless'>
                <div className='podcast-card pb-2'>
                    <div className='d-flex' >
                        <div>
                            <label className='cnow-form-label fw-bold'>Order</label>
                            <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                        </div>

                        <div className='flex-grow-1 pe-0'>
                            <div className="d-flex justify-content-between">
                                <span className='fw-bold'>{moment(date).format("MMMM Do YYYY")}</span>
                                <div className='ms-1 ps-0'>
                                    {
                                        <div>
                                            {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { showMore(false) }}>{`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                                            {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { showMore(true) }}>{`${data.length} total, Show more`} <i className='fe fe-chevron-down ms-1' /></div>}
                                        </div>
                                    }
                                </div>

                            </div>
                            {more &&
                                <>
                                    <div className='pt-2'>
                                        {images && images?.length > 0 && <>
                                            <div className='cnow-form-title'>{'Images'}</div>
                                            {images?.map((elem) => {
                                                return (
                                                    <div className="d-flex justify-content-between py-2">
                                                        <div className="d-flex">
                                                            <div>
                                                                <ImageViewer url={elem?.image_url} type='thumb' cssClass='thumb' />
                                                            </div>
                                                            <div className="ps-2">
                                                                <div className='small'>© {elem?.image_credits}</div>
                                                                <div>{elem?.image_caption}</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {elem?.signature ? <div className="text-decoration-underline cursor-pointer text-small" onClick={() => { handleDigitalSignature(elem?.signature) }}>Electronic Authentication</div> : <div className="text-decoration-underline cursor-pointer text-small" onClick={() => { handleDocumentUpload(elem.legal_pdf_url) }}>Document Uploaded</div>}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }

                                        </>
                                        }
                                    </div>

                                    <div className='pt-2'>
                                        {podcasts && podcasts?.length > 0 && <>
                                            <div className='cnow-form-title'>{'Podcasts'}</div>
                                            {podcasts?.map((elem) => {
                                                return (
                                                    <>
                                                        <div className="d-flex justify-content-between py-2">
                                                            <div className="d-flex">
                                                                <div className='position-relative'>
                                                                    {elem?.podcast_image_url && <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                                                                        {elem.podcast_type === 'audio' ? <ImageComponent src="/img/icons/podcast-outline.svg" style={{ width: '100%', height: '90%' }} alt="playlist"/>
                                                                            : <ImageComponent src="/img/icons/podcast_video.svg" style={{ width: '100%', height: '70%' }} alt="playlist" />}
                                                                    </div>}
                                                                    <ImageViewer url={elem?.podcast_image_url} type='thumb' cssClass='thumb' />
                                                                </div>
                                                                <div className="ps-2">
                                                                    <div className='small'>© {elem?.podcast_credits}</div>
                                                                    <div>{elem?.podcast_name}</div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {elem?.signature ? <div className="text-decoration-underline cursor-pointer text-small" onClick={() => { handleDigitalSignature(elem?.signature) }}>Electronic Authentication</div> : <div className="text-decoration-underline cursor-pointer text-small" onClick={() => { handleDocumentUpload(elem.legal_pdf_url) }}>Document Uploaded</div>}
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                            }

                                        </>
                                        }
                                    </div>

                                </>

                            }
                        </div>

                        <div className='ms-1 ps-0'>
                            <div>&nbsp;</div>
                            {type !== 'playlist' && <Button size='sm' color='light'
                                icon={`edit`}
                                onClick={() => { history.push(`/${type}/${id}/editor/legal`) }} />}
                        </div>
                    </div>
                </div>
            </div>


            <Modal className='modal-main' show={showModal} size='xl' onHide={() => { setShowModal(false) }}>
                <Modal.Body>
                    <div className="d-flex justify-content-end"><Button size='sm' color='light' icon={`close`} onClick={() => { setShowModal(false) }} /> </div>
                    <div className="py-5 fw-bold d-flex justify-content-center"> Electronic Authentication by :  {signature} </div>
                </Modal.Body>
            </Modal>


        </>
    )
}