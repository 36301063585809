import React, { useState } from "react";
import { useSelector } from "react-redux";
import ImageViewer from "../viewers/image";
import { toast } from 'react-toastify';
import { uniqBy } from "lodash";
import ImageCreditList from "../preview/imageCreditList";
import ImageComponent from "../image";
const PodcastRelease = (props) => {
  const [podcasts, setPodcasts] = useState([])
  const entity = useSelector((state) => state[props.id]);
  let currentPodcasts = entity ? entity.podcasts : [];
  const handlePodcastClick = (value, id) => {
    if (props.mode && props.mode !== value) { toast.error(`Please Select ${props.mode === "upload_document" ? 'Upload Document Option' : 'Digital Signature Option'}`); }
    const selectedPodcasts = [...podcasts]
    const index = selectedPodcasts.findIndex((elem) => elem.entity_id === id)
    if (index === -1) selectedPodcasts.push({ entity_id: id, entity_type: 'podcast', mode: value })
    else if (index !== -1 && selectedPodcasts[index].mode === value) { selectedPodcasts.splice(index, 1) }
    else selectedPodcasts[index].mode = value
    setPodcasts(selectedPodcasts)
    props.handlePodcastClick(selectedPodcasts)
    props.handleUploadMode(value)
  }

  return (
    <div>
      <hr className='cnow-hr' />
      <div className="col">
        <label className="cnow-form-title">PODCAST RELEASE *</label>
      </div>
      <div className="row text-center">
        <div className="col-1">
          <label className="form-check-label d-block small">
            Authorized CultureNow electronically
          </label>
        </div>
        <div className="col-1">
          <label className="form-check-label d-block small">
            Upload Signed Document
          </label>
        </div>
        <div className="col-10"></div>
      </div>
      {currentPodcasts &&
        uniqBy(currentPodcasts, 'podcast_id').filter((elem) => !elem.is_legal).map((podcast) => <LegalPodcastButton podcast={podcast} key={podcast.id} onClick={(value) => { handlePodcastClick(value, podcast.podcast_id) }} selectedPodcasts={podcasts} />)}
    </div>
  );
};

export default PodcastRelease;

const LegalPodcastButton = (props) => {
  const podcast = props.podcast
  const selectedPodcasts = props.selectedPodcasts
  const handleCheckedBox = (type) => {
    const index = selectedPodcasts.findIndex((elem) => elem.entity_id === podcast.podcast_id)
    if (index !== -1 && selectedPodcasts[index].mode === type) return true
    else return false
  }
  return (
    <div className="row mb-4 gx-1" key={`${podcast.id}-podcast`}>
      <div className="col-1 text-center">
        <div className="form-check-inline">
          <input type="radio" className="form-check-input" name={`type-${podcast.id}`} value="digital_signature" checked={handleCheckedBox('digital_signature')} onClick={(e) => { props.onClick(e.target.value) }} />
        </div>
      </div>
      <div className="col-1 text-center">
        <div className="form-check-inline">
          <input type="radio" className="form-check-input" name={`type-${podcast.id}`} value="upload_document" checked={handleCheckedBox('upload_document')} onClick={(e) => { props.onClick(e.target.value) }} />
        </div>
      </div>
      <div className="col-10">
        <div className="d-flex ms-4">
          <div className="me-3">
            <div className='position-relative'>
              {podcast?.podcast_image_url && <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                {podcast.podcast_type === 'audio' ? <ImageComponent src="/img/icons/podcast-outline.svg" style={{ width: '100%', height: '90%' }} alt="playlist" />
                  : <ImageComponent src="/img/icons/podcast_video.svg" style={{ width: '100%', height: '70%' }} alt="playlist" />}
              </div>}
              <ImageViewer url={podcast?.podcast_image_url} type='thumb' cssClass='thumb' />
            </div>
          </div>
          <div>
            <div>{podcast?.podcast_name}</div>
            <div className="small"><ImageCreditList data={podcast?.credits} /></div>
          </div>
        </div>
      </div>
    </div>
  )
}



