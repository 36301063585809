
import { ApiCall } from './utils'

//featured
const getFeatured = (params) => ApiCall('post', `/homepage/search`, params)

const updateFeatured = (params) => ApiCall('post', `/homepage`, params)

const createHomepageImage = (params) => ApiCall('post', `/homepage/image`, params)

const deleteFeatured = (id) => ApiCall('delete', `/homepage/${id}`)

//onThisDay Search

const search = (params) => ApiCall('post', `/on_this_day/search`, params)

const searchByElastic = (from, size, params) => ApiCall('post', `/search/on_this_day/${from}/${size}`, params)


export default {
    getFeatured,
    updateFeatured,
    deleteFeatured,
    search,
    searchByElastic,
    createHomepageImage
}

