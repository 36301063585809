import React from 'react'
import { useSelector } from 'react-redux';
import ImageViewer from '../../components/viewers/image';


const TourImages = (props) => {
    const entity = useSelector(state => state[props.id]);


    let currentImages = entity.images?.filter(x => (x.type === 'image'))
    let currentDocuments = entity ? entity.images?.filter(x => x.type === 'document') : []
    let stopImages = entity.stops
    return (
        <div>
            {currentImages && currentImages.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>Tour Images</div>
                    <div className='image-box'>
                        {currentImages.map((item, index) => { return <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' /> }
                        )}
                    </div>
                </>
            }
            {currentDocuments && currentDocuments.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>Tour Documents</div>
                    <div className='image-box'>
                        {currentDocuments.map((item, index) => { return <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' /> }
                        )}
                    </div>
                </>
            }
            {
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>Tour Stop Images</div>
                    <div className='image-box'>
                        {stopImages?.map((item, index) => {
                            return <>   <ImageViewer key={index} entityType={'image'} entityId={item.image_id} type='thumb' cssClass='thumb p-1' /> </>
                        }

                        )}
                    </div>
                </>
            }
        </div>
    )
}

export default TourImages