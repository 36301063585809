/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { uniqBy } from 'lodash'
import Dragula from 'react-dragula'
import uniqid from 'uniqid'
import PageTitle from '../../components/pageTitle'
import EntityPodcast from '../../components/editors/entityPodcast'
import RelatedPlaylist from '../../components/editors/relatedPlaylist'
import { PodcastsCard } from '../../components/editors/relatedPodcast'
import { newTempId, personName, handleReorderEventPodcast } from '../../helpers'
import { useHistory, useLocation } from 'react-router-dom';
import ImageViewer from '../../components/viewers/image'
import PreviewButton from '../../components/buttons/preview'
import Button from '../../components/buttons/default'
import ImageComponent from '../../components/image'

const _api = require('../../api')

const EventPodcastType = (props) => {
    const dispatch = useDispatch()
    const entity = useSelector((state) => state[props.id])
    const [recipePodcast, setrecipePodcast] = useState([]);
    const [showTable, setShowTable] = useState(true);
    const history = useHistory();
    const location = useLocation();
    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'event-podcast'
    useEffect(() => {
        getAllRecipePodcast()
        let containers = []
        let types = ['event_trailer_recording', 'related_podcasts', 'recipe_podcasts']
        types.forEach((type) => containers.push(document.getElementById(`${dragulaContainer}-${type}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorderEventPodcast(entity.podcasts, types, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, podcasts: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End
    const handleEdit = (route) => {
        history.push({ pathname: route })
        dispatch({ type: 'podcasts', payload: { entityPodcasts: entity.podcasts, source: location.pathname } })
    }


    const updatePodcasts = async (data, type) => {
        let podcasts = entity.podcasts || []
        let items = (data?.length && Array.isArray(data)) ? data : data?.entityPodcasts
        if (items?.length > 0) {
            for (let i in items) {
                let podcast = {
                    'id': newTempId(),
                    'podcast_id': items[i].id,
                    'podcast_name': items[i].name,
                    'type': type,
                    'podcast_image_url': items[i]?.image?.url ? items[i]?.image?.url : entity?.image?.url ? entity?.image?.url : null,
                    'podcast_type': items[i].type,
                    'podcast_credits': items[i].credits?.length ? personName(items[i].credits[0]) : '',
                    'podcast_duration': items[i].duration,
                    'sort': podcasts.length + 1
                }
                podcast[`event_id`] = entity.id
                podcasts.push(podcast)
            }
        }
        podcasts = uniqBy(podcasts, 'podcast_id')
        podcasts.forEach((item, index) => item.sort = index + 1)
        podcasts = await _api.event.updatePodcast(entity.id, podcasts)
        dispatch({ type: 'entity', payload: { ...entity, podcasts: podcasts } })
        //refresh dragula
        setDragula(uniqid())
    }

    const removePodcast = async (id) => {
        const index = entity.podcasts.findIndex(x => x.id === id)
        if (entity.podcasts[index]) {
            if (!entity.podcasts[index].id.startsWith('temp.'))
                await _api.event.deletePodcast(entity.podcasts[index].id)

            entity.podcasts.splice(index, 1)
            entity.podcasts.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, podcasts: entity.podcasts } })
        }
    }

    const getAllRecipePodcast = () => {
        let allRecipePodcasts = []
        if (entity?.recipes) {
            for (let result of entity?.recipes) {
                if (result?.podcasts) {
                    for (let podcast of result?.podcasts) {
                        allRecipePodcasts.push(podcast)
                    }
                }
            }
            setrecipePodcast(allRecipePodcasts)
        }

    }

    return (
        <>


            <EntityPodcast id={props.id} type={'event'}
                title={'Event Trailer and Recording'}
                items={(entity?.podcasts?.length > 0 ? entity?.podcasts?.filter(x => x.type === 'event_trailer_recording') : [])}
                onDelete={async (id) => await removePodcast(id)}
                onUpdate={async (items) => await updatePodcasts(items, 'event_trailer_recording')}
                dragulaId={`${dragulaContainer}-event_trailer_recording`} dragula={dragula}
                offsetIndex={0}
            />

            <EntityPodcast id={props.id} type={'event'}
                title={'Related Podcasts'}
                items={(entity?.podcasts?.length > 0 ? entity?.podcasts?.filter(x => x.type === 'related_podcasts') : [])}
                onDelete={async (id) => await removePodcast(id)}
                onUpdate={async (items) => await updatePodcasts(items, 'related_podcasts')}
                dragulaId={`${dragulaContainer}-related_podcasts`} dragula={dragula}
                offsetIndex={entity?.podcasts?.filter(x => x.type === 'event_trailer_recording')?.length || 0}
            />
            {recipePodcast.length > 0 && (
                <>
                    <div className='cnow-form-title'>{`Recipe Podcasts `}</div>

                    <div className='table'>
                        <div className='d-flex bg-light small py-2 ps-2'>
                            <div style={{ width: '70px' }} className='fw-bold'>Order</div>
                            <div style={{ width: '90px' }} className='fw-bold'>Media</div>
                            <div className='flex-grow-1 fw-bold'>Podcast, Title, Credits</div>
                            <div className='text-end' style={{ width: '150px' }}>
                                {recipePodcast?.length} Podcasts
                                {showTable === true && <i className='fe fe-chevron-down ms-1' style={{ 'cursor': 'pointer' }} onClick={() => setShowTable(false)} />}
                                {showTable === false && <i className='fe fe-chevron-up ms-1' style={{ 'cursor': 'pointer' }} onClick={() => setShowTable(true)} />}
                            </div>
                        </div>
                        {
                            showTable &&
                            <div id={props.dragulaId} key={`${props.dragula}${props.cssId}`} className='dragula-container'>
                                {
                                    recipePodcast?.map((item, index) => {
                                        return (
                                            <div key={item.id + index} id={item?.id} className={`${item?.id} text-left dragula-item d-flex`}>
                                                <div style={{ width: '70px' }}>
                                                    <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={props?.offsetIndex !== undefined ? props.offsetIndex + index + 1 : index + 1} autoComplete='off' />
                                                </div>
                                                <div style={{ width: '90px' }}>
                                                    <div className='position-relative'>
                                                        {item?.podcast_image_url && <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                                                            {item.podcast_type === 'audio' ? <ImageComponent src="/img/icons/podcast-outline.svg" style={{ width: '100%', height: '90%' }} alt="playlist" />
                                                                : <ImageComponent src="/img/icons/podcast_video.svg" style={{ width: '100%', height: '70%' }} alt="playlist" />}
                                                        </div>}
                                                        <ImageViewer url={item?.podcast_image_url} type='thumb' cssClass='thumb' />
                                                    </div>
                                                </div>
                                                <div className='text-start small flex-grow-1'>
                                                    <div className='bg-light fw-bold'>{item?.podcast_name} </div>
                                                    <div>by {item?.podcast_credits}</div>
                                                </div>
                                                <div className='text-end' style={{ width: '150px' }}>
                                                    <div className='d-flex gap-1 justify-content-end'>
                                                        <PreviewButton size='md' icon='preview' link={`podcast/${item?.podcast_id}`} />
                                                        <Button size='md' icon='edit' onClick={() => { handleEdit(`/podcast/${item?.podcast_id}/editor`) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        }
                    </div>
                </>
            )}
        </>
    )
}

const EventPodcast = (props) => {
    const [key, setKey] = useState(uniqid())
    const event = useSelector(state => state[props.id]);
    return (
        <>
            <PageTitle title={event?.name} />
            <hr />
            <EventPodcastType id={props.id} key={`event-podcast-type-${key}`} refresh={() => { setKey(uniqid()) }} />
            {event?.featured_projects?.length > 0 &&
                <>
                    <hr />
                    <PodcastsCard title={'Featured Project Podcasts'} data={event?.featured_projects.filter((elem) => elem.featured)} type={'site'} titleLabel={'Site Title'} />
                </>
            }
            {event?.participants?.length > 0 && <>
                <hr />
                <PodcastsCard title={'Participant Podcasts'} data={event?.participants?.filter((elem) => elem?.is_primary)} type={'person'} titleLabel={'Person Name'} />
            </>
            }
            {event?.playlists?.length > 0 && <RelatedPlaylist id={props.id} type="event" key={`related-podcast-editor-${key}`} refresh={() => { setKey(uniqid()) }} />}
        </>
    )
}

export default EventPodcast
