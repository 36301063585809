/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import swal from "sweetalert";

import Button from "../../../components/buttons/default";
import LinkButton from "../../../components/buttons/link";
import PreviewButton from "../../../components/buttons/preview";
import Pagination from "../../../components/pagination";
import ResultCount from "../../../components/resultCount";
import ScrollToTop from "../../../components/scrollToTop";
import Spinner from "../../../components/spinner";
import Icon from "../../../components/viewers/icon";
import ImageViewer from "../../../components/viewers/image";
import LongTextView from "../../../components/viewers/longText";

import AwardList from "../../../components/table/awardList";
import DateList from "../../../components/table/dateList";
import DesignationList from "../../../components/table/designationList";
import PeopleList from "../../../components/table/peopleList";
import TagList from "../../../components/table/tagList";

import CategoryList from "../../../components/table/categoryList";
import LocationList from "../../../components/table/locationList";
import Playlist from "../../../components/table/playlist";
import PodcastList from "../../../components/table/podcastList";
import RelatedMapList from "../../../components/table/relatedMapList";
import RelatedPeopleList from "../../../components/table/relatedPeopleList";
import RelatedSiteList from "../../../components/table/relatedSiteList";
import ThemeList from "../../../components/table/themeList";
import TableHeader from "../../../components/tableHeader";
import MissingRequiredInfoList from "../../../components/table/missingInfo";
import { TABLE_ROWS, getCategoryName } from "../../../helpers";
import RelatedEventSeriesList from "../../../components/table/relatedEventSeries";
import RelatedTourList from "../../../components/table/relatedTourList";
import RelatedEventList from "../../../components/table/relatedEvent";
import RelatedRecipeList from "../../../components/table/relatedRecipeList";

const _api = require("../../../api");
const columns = require("../../../data/columns_site.json");

const SiteTable = (props) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const [searchResult, setSearchResult] = useState([]);

  const [keywordValue, setKeywordValue] = useState("");
  const [keyword, setKeyword] = useState("");
  const take = 50;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(columns[0]);

  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    search();
  }, [page, sort, keyword, user.id]);

  const search = async () => {
    // const from = 0
    let sortBoolean = true;
    const params = {
      bool: {
        must: [{ match: { deleted: "false" } }],
        must_not: [
          {
            match: {
              created_via: "ADMIN"
            }
          }
        ]
      }
      // ...checkUserType
    };
    if (keyword) {
      sortBoolean = false;
      params.bool.must.push({
        multi_match: {
          query: keyword,
          fields: [
            "name^12",
            "alias^6",
            "people.person_name^9",
            "people.person_first_name^3",
            "people.person_last_name^3",
            "locations.name",
            "locations.city",
            "locations.state",
            "locations.country",
            "categories.category_name",
            "categories.subcategory_name",
            "tags.tag_name",
            "related_sites.related_site_name",
            "related_people.related_person_name",
            "podcasts.podcast_name",
            "people.role_name",
            "people.profession.profession_name",
            "related_people.org_type.organization_name",
            "locations.address",
            "theme.theme_name"
          ],
          fuzziness: 2,
          prefix_length: 2
        }
      });
    }

    const finalParams = {
      query: params
    };
    if (sortBoolean) {
      finalParams.sort = [{ modified_date: { order: "desc" } }];
    }

    showSpinner(true);
    _api.site
      .searchByElastic((page - 1) * 50, take, finalParams)
      .then((results) => {
        setSearchResult(results?.hits);
        showSpinner(false);
      });
  };
  const deleteSite = (site) => {
    swal({
      title: `Delete Site`,
      text: `Are you sure that you want to delete ${site?.name}?`,
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true
    }).then((status) => {
      _api.site.deleteById(site?.id).then(() => {
        search();
      });
    });
  };

  const updateLive = (id, live) => {
    showSpinner(true);

    let selectedSearchResult = searchResult.hits.filter(
      (item) => item._id === id
    );
    if (selectedSearchResult?.length === 0) {
      showSpinner(false);
      return swal({
        title: `Something went wrong`,
        icon: "error"
      });
    }
    let selectedResult = selectedSearchResult[0]._source;

    let dateDescription = (selectedResult.dates ?? []).filter(
      (e) => e.description !== ""
    );
    let category = getCategoryName(selectedResult?.categories);
    if (live === true) {
      if (
        selectedResult?.name &&
        category &&
        selectedResult?.locations &&
        selectedResult?.locations?.[0]?.address &&
        (selectedResult?.description || dateDescription?.length > 0) &&
        selectedResult?.image?.url &&
        selectedResult?.vanity_url
      ) {
        _api.site.updateLive(id, live).then(async (_) => {
          await search();
          swal({
            title: "Site is Live!",
            icon: "success",
            text: `${selectedResult?.name} is now Live!`,
            buttons: "Ok"
          });
        });
      } else {
        swal({
          title: `${selectedResult.name} cannot go Live!`,
          icon: "error",
          text: `Please fill the mandatory fields:
          ${selectedResult?.name ? "" : "Title is missing."}
          ${category ? "" : "Category is missing."} 
          ${
            selectedResult?.locations &&
            selectedResult?.locations?.[0] &&
            selectedResult?.locations?.[0]?.address
              ? ""
              : "Location is missing."
          } 
          ${
            selectedResult?.description || dateDescription?.length > 0
              ? ""
              : "Description is missing."
          } 
          ${selectedResult?.image?.url ? "" : "Image is missing."} 
          ${selectedResult?.vanity_url ? "" : "Vanity URL is missing."}`,

          buttons: "Go back",
          dangerMode: true
        });
      }
    } else {
      _api.site.updateLive(id, live).then(async (_) => {
        await search();
        swal({
          title: "Not Live!",
          icon: "info",
          text: `${selectedResult?.name} is Not Live.`,
          buttons: "Ok"
        });
      });
    }
    showSpinner(false);
  };

  return (
    <>
      <div className="mt-3 container-fluid">
        <div className="row g-1">
          <div className="col">
            <div className="input-group">
              <span className="input-group-text border-end-0">
                <Icon name="search" />
              </span>
              <input
                className="form-control bg-light border-start-0"
                type="text"
                placeholder="Search by a person, a category, a site name, date"
                value={keywordValue}
                onChange={(event) => {
                  setKeywordValue(event.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setPage(1);
                    setKeyword(keywordValue);
                  }
                }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-auto">
            <Button
              label="Search"
              onClick={() => {
                setPage(1);
                setKeyword(keywordValue);
              }}
            />
          </div>
          <div className="col-auto">
            <Button
              label="Add a Site"
              color="danger"
              icon="plus-white"
              onClick={() => history.push("/site/create")}
            />
          </div>
        </div>
      </div>

      <div className="mt-3 p-0 container-fluid">
        <div className="card">
          <div className="card-header bg-light p-2">
            <div className="d-flex justify-content-between gap-2 align-items-end">
              <div>
                <h4 className="mb-0">Sites</h4>
                <div className="small">
                  <ResultCount
                    page={page}
                    take={take}
                    count={searchResult?.total?.value}
                  />
                  {keyword && (
                    <span className="badge badge-search">
                      {keyword}{" "}
                      <Icon
                        name="close"
                        size={10}
                        left={4}
                        cursor="pointer"
                        onClick={() => {
                          setKeyword("");
                          setKeywordValue("");
                        }}
                      />
                    </span>
                  )}
                </div>
              </div>

              <Pagination
                page={page}
                take={take}
                count={searchResult?.total?.value}
                setPage={setPage}
              />

              <div className="dropdown">
                <button
                  className="btn btn-light dropdown-toggle"
                  type="button"
                  id="sort"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Sort by: {sort.name}
                </button>
                <ul className="dropdown-menu">
                  {columns
                    .filter((x) => x.sort === true)
                    .map((column, index) => (
                      <button
                        key={index}
                        className="dropdown-item"
                        onClick={() => setSort(column)}
                      >
                        {column.name}
                      </button>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <Spinner display={spinner}>
            <div className="table-responsive cnow-table">
              <table className="table table-striped table-hover table-bordered">
                <thead>
                  <tr className="bg-light">
                    {columns
                      .filter((x) => x.display === true)
                      .map((column, index) => (
                        <TableHeader
                          key={index}
                          column={column}
                          onSort={(column) => setSort(column)}
                        />
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {searchResult?.hits?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="row g-1">
                          <div className="col-auto">
                            <div className="form-check mt-1">
                              <input
                                className="form-check-input align-middle"
                                type="checkbox"
                                name="select_site"
                                value={item?._source.id}
                              />
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="btn-list">
                              <LinkButton
                                size="sm"
                                icon="edit"
                                to={`/site/${item?._source?.id}/editor`}
                              />
                              <Button
                                size="sm"
                                icon="delete"
                                onClick={() => deleteSite(item?._source)}
                              />
                              <PreviewButton
                                size="sm"
                                link={`site/${
                                  item?._source?.vanity_url || item?._source?.id
                                }`}
                              />
                              <Button
                                size="live"
                                icon={
                                  item?._source?.live === true
                                    ? "live"
                                    : "not-live"
                                }
                                color={
                                  item?._source?.live === true ? "" : "danger"
                                }
                                onClick={() =>
                                  updateLive(
                                    item?._source.id,
                                    item?._source?.live === true ? false : true
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <ImageViewer
                          url={item?._source?.image?.url}
                          type="thumb"
                          cssClass="thumb"
                        />
                      </td>
                      {/* {console.log(item,"_tem?.source?.data.name")} */}
                      <td className="fw-bold">{item?._source?.name}</td>
                      <LocationList data={item?._source?.locations} />
                      <PeopleList data={item?._source?.people} />
                      <CategoryList data={item?._source?.categories} />
                      <ThemeList data={item?._source?.themes} />
                      <DateList data={item?._source?.dates} />
                      <MissingRequiredInfoList data={item?._source} />
                      <td>{item?._source?.id}</td>
                      <TagList data={item?._source.tags} />
                      <td>
                        <LongTextView lines={TABLE_ROWS + 1}>
                          {item?._source?.description ||
                            (item?._source?.dates &&
                              item?._source?.dates?.filter(
                                (e) => e.description !== ""
                              )[0]?.description)}
                        </LongTextView>
                      </td>
                      <PodcastList data={item?._source?.podcasts} />
                      <AwardList data={item?._source?.awards} />
                      <DesignationList data={item?._source?.designations} />
                      <Playlist data={item?._source?.playlists} />
                      <RelatedEventSeriesList
                        data={item?._source?.event_series}
                      />
                      <RelatedRecipeList data={item?._source?.recipes} />
                      <RelatedSiteList data={item?._source?.related_sites} />
                      <RelatedPeopleList data={item?._source?.related_people} />
                      <RelatedEventList data={item?._source?.events} />
                      <RelatedTourList data={item?._source?.tours} />
                      <RelatedMapList data={item?._source?.maps} />
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-between m-3">
              <div className="cols">
                <Pagination
                  page={page}
                  take={take}
                  count={searchResult?.total?.value}
                  setPage={setPage}
                />
              </div>
            </div>
          </Spinner>
        </div>
      </div>

      <ScrollToTop />
    </>
  );
};

export default SiteTable;
