import React, {useEffect, useState} from 'react'
import moment from "moment";
import ImageComponent from '../image';

const PdfView = ({type, url, cssClass, onClick=()=>{} }) => {
    let imageType = type || 'thumb'; //either thumbnail or the PDF preview of 1st page
    let [imageUrl, setImageUrl] = useState(`/img/${type}.png`);
    let width = imageType === "thumb" ? "15px" : "40px"
    let height = imageType === "thumb" ? "15px" : "40px"
    useEffect(() => {
        if (url) {
          // eslint-disable-next-line
          url = url?.toLowerCase().endsWith('.pdf')? url.replace('.pdf', '.png') : url;
          setImageUrl(url.toString()?.replace('original', `${imageType}`) + "?" + moment().unix());
        } 
      }, [url]);

      const handleError = () => {
        setImageUrl(`/img/${type}.png`);
      };

  return (
    <>  
    <div className='img-small position-relative'>
        <ImageComponent src={imageUrl} className={cssClass + ' border border-dark'} alt={''}
          onError={handleError} draggable={false} onClick={onClick}
        />
        <div 
            style={{
                width: width,
                height: height,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "linear-gradient(to bottom right, #ae0000 50%, white 50%)",
            }}
            className='position-absolute  bottom-0 end-0'
            >
        </div>
    </div>
    </>
  )
}

export default PdfView;