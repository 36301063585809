import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/buttons/default";
import Spinner from "../../components/spinner";
import { default as ImageViewer } from "../../components/viewers/image";
import LongTextView from "../../components/viewers/longText";
import { numberToTimeConvert, validURL } from "../../helpers/index";
import CreditList from '../../components/table/creditList'
import RelatedItemList from '../../components/table/relatedItemList'
import TagList from '../../components/table/tagList'
import ImageComponent from "../../components/image";

const _api = require('../../api');

const podcastTypes = {
  audio: {
    title: "Upload Audio",
    description: "Podcasts must be MP3 or OGG format.",
    accept: { "audio/*": [] },
  },
  video: {
    title: "Upload Video",
    description:
      "Video must be uploaded on Vimeo or YouTube. If you do not have an account, please share the file with us and we will upload it for you.",
    accept: { "video/*": [] },
  },
  dataBase: {
    title: "Upload Video",
    description:
      "Video must be uploaded on Vimeo or YouTube. If you do not have an account, please share the file with us and we will upload it for you.",
    // accept: { "video/*": [] },
  },
};

const PodcastUploader = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [type, setType] = useState(props.type);
  const [files, setFiles] = useState([]);
  const updateData = useSelector(state => state[props.updateID])
  const [videoUrl, setVideoUrl] = useState(updateData?.url ? updateData.url : '');
  const [videoEmbedCode, setVideoEmbedCode] = useState("");
  const [spinner, showSpinner] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const podcasts = useSelector((state) => state.podcasts);
  const entity = useSelector(state => state[props.entityId])
  const user = useSelector(state => state.user);
  const take = 50;
  const [page, setPage] = useState(1);;
  const search = useCallback(() => {
    showSpinner(true);

    let sortBoolean = true
    const params = {
      "bool": {
        "must": [
          { "match": { "deleted": "false" } },
        ]
      }
    };

    if (keyword) {
      sortBoolean = false
      params.bool.must.push({
        "multi_match": {
          "query": keyword,
          "fields": [
            "name^3",
            "type",
            "credits.person_name^2",
            "tags.tag_name",
            "related_item.name",
          ],
          "fuzziness": 2,
          "prefix_length": 2
        }
      });
    }
    const finalParams = {
      query: params
    }
    if (sortBoolean) {
      finalParams.sort = [{ "modified_date": { "order": "desc" } }]
    }

    _api.podcast.searchByElastic((page - 1) * 50, take, finalParams).then(response => {
      setSearchResult(response?.hits)
      showSpinner(false)
    })
  }, [keyword])

  useEffect(() => {
    search()
  }, [search]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: type ? podcastTypes[type].accept : "audio/*",
    onDrop: async (acceptedFiles) => uploadPodcast(acceptedFiles),
    multiple: props.updateID ? false : true,
  });

  const uploadPodcast = async (items) => {
    { console.log("callled"); }
    let pods = podcasts?.entityPodcasts?.length ? podcasts.entityPodcasts : [];
    let tempFiles = [...files]
    items && items.forEach(d => tempFiles.push(d))
    setFiles(tempFiles);
    items.forEach(async (file, index) => {
      let response = null

      // 1. create podcast / get podcast
      if (props.updateID) {
        response = updateData
      } else {
        response = await _api.podcast.update({
          type: type,
          created_via: "ADMIN"
        });
      }
      let podcast = response;

      // 2. get file upload url
      const ext = file.name.split(".")[1];
      const data = {
        name: `podcast/${podcast.id}/original.${ext}`,
        contentType: file.type,
      };
      response = await axios.post("file/upload-url", data);

      // 3. upload file to storage
      await axios.put(response?.data.url, file, {
        headers: { "Content-Type": file.type },
      });

      podcast.url = response?.data.url.split("?")[0];
      podcast.fileName = file.name;

      //get audio duration
      var audio = new Audio();
      audio.onloadedmetadata = async () => {
        podcast.duration = numberToTimeConvert(audio.duration);

        //   adding user id to podcast upload 
        if (user?.id) {
          podcast.user_id = user.parent_id || user.id;
        }

        // 4. update podcast url
        await _api.podcast.update(podcast);
        pods.push(podcast);

        // 5. attach to entity
        if (entity && entity.id) {
          podcast.image_id = entity?.image?.id ? entity?.image?.id : null;
          await _api.podcast.update(podcast);
        }

        items[index].url = podcast.url;
        let tempFiles = [...files]
        items && items.forEach(d => tempFiles.push(d))
        setFiles(tempFiles);

        if (props.updateID) {
          dispatch({ type: "entity", payload: { ...updateData, url: pods[0].url } });
        } else {
          dispatch({ type: "entity", payload: Object.create(pods[0]) });
        }

        dispatch({ type: "podcasts", payload: { entityPodcasts: pods, source: '/podcast' } });

        if (props.updateID) props.onHide()
      };
      audio.src = podcast.url;
    });
  };

  const saveVideoPodcast = async () => {
    if (videoUrl) {
      // 1. create podcast / update podcast
      let response = null
      if (props.updateID) {
        response = await _api.podcast.update({
          ...updateData,
          url: videoUrl || "",
          user_id: user.parent_id ? user.parent_id : user.id,
          created_via: "ADMIN"

        });
      } else {
        response = await _api.podcast.update({
          type: type,
          url: videoUrl || "",
          user_id: user.parent_id ? user.parent_id : user.id,
          created_via: "ADMIN"

        });
      }
      // 2. attach to entity
      if (response && entity && entity.id) {
        let entityPodcast = [{
          id: response?.id,
          type: response?.type,
          url: response?.url,
          credits: response?.credits || [],
          name: response?.name || '',
          image: entity.image,
          user_id: user.parent_id ? user.parent_id : user.id
        }];

        response.image_id = entity?.image?.id ? entity?.image?.id : null
        _api.podcast.update(response);
        handleSave(entityPodcast)

      }
      if (props.updateID) { }
      else history.push({ pathname: `/podcast/${response?.id}/editor`, url: location.pathname })

      props.onHide(false);
    } else {
      toast.error('Please enter the video url!');
    }
  };

  const handleNext = async () => {
    // handleSave(podcasts)
    // history.push({ pathname: `/podcast/${podcasts[0]?.id}/editor`, url: location.pathname });

    await handleSave(podcasts?.entityPodcasts)
    dispatch({ type: 'podcasts', payload: { entityPodcasts: entity?.podcasts, source: location.pathname } })
    history.push({ pathname: `/podcast/${podcasts?.entityPodcasts?.[0]?.id}/editor` })
  };

  const handleSelect = (item) => {
    let items = selectedItems

    if (items?.findIndex(x => x.id === item.id) >= 0) items = items.filter(x => x.id !== item.id)
    else items.push(item)

    setSelectedItems(items)
  };

  const onVideoUrlChange = (e) => {
    setVideoUrl(e.target.value)
    const loc = validURL(e.target.value) ? new URL(e.target.value) : null;
    let iframe = "";
    let host = "";
    let src = ""
    if (loc) {
      host = loc.host;
      if (host === "www.youtube.com") {
        const params = loc.searchParams;
        const v_code = params.get('v');
        src = `https://www.youtube.com/embed/${v_code}`
      } else if (host === "youtu.be") {
        src = `https://www.youtube.com/embed${loc.pathname}`
      } else {
        src = `https://player.vimeo.com/video${loc.pathname}`
      }
      iframe = `<iframe width="550" height="300" src=${src}
      title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>`;
      setVideoEmbedCode(iframe)
    }
  }

  const handleSave = (values) => {
    if (props.onSave) {
      props.onSave(values);
      props.onHide();
    }
  }

  return (
    <Modal show={props.show} size='xl' onHide={() => props.onHide(false)}>
      <Modal.Body>
        {!type && (
          <>
            <div className='d-flex justify-content-between'>
              <div className='cnow-form-title'>UPLOAD PODCAST</div>
              <div>
                <Button
                  size='sm'
                  icon='close'
                  onClick={() => props.onHide(false)}
                />
              </div>
            </div>
            <div className='btn-list'>
              {!props.onlyFromDB && <><Button label='Audio' onClick={() => setType("audio")} />
                <Button label='Video' onClick={() => setType("video")} /></>}

              {props.entityId && <Button
                label='Add Podcast from cultureNow Database'
                onClick={() => setType("dataBase")}
              />}
            </div>
          </>
        )}

        {type === "audio" && (
          <>
            <div className='d-flex justify-content-between'>
              <div className='cnow-form-title'>{podcastTypes[type].title}</div>
              <div>
                <Button
                  size='sm'
                  label='Save'
                  color='light'
                  onClick={() => handleSave(podcasts)}
                  className='me-2'
                />
                <Button
                  size='sm'
                  icon='close'
                  onClick={() => props.onHide(false)}
                />
              </div>
            </div>
            <div className='small'>{podcastTypes[type].description}</div>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <div className='cnow-form-title'>{podcastTypes[type].title}</div>
              <p>Drop your file(s) anywhere in this window</p>
              {files?.length > 0 && files?.length !== podcasts?.entityPodcasts?.length && (
                <>
                  <div className='progress w-25'>
                    <div
                      className='progress-bar progress-bar-striped progress-bar-animated bg-danger'
                      role='progressbar'
                      style={{
                        width: `${parseInt(
                          ((podcasts?.entityPodcasts?.length || 1) * 100) / files?.length
                        )}%`,
                      }}
                    ></div>
                  </div>
                  <p>
                    Uploading {podcasts?.entityPodcasts?.length || 1} out of {files?.length}
                  </p>
                </>
              )}
            </div>
          </>
        )}
        {type === "video" && (
          <>
            <div className='d-flex justify-content-between'>
              <div className='cnow-form-title'>{podcastTypes[type].title}</div>
              <div>
                <Button
                  size='sm'
                  label='Save'
                  color='light'
                  onClick={saveVideoPodcast}
                  className='me-2'
                />
                <Button
                  size='sm'
                  icon='close'
                  onClick={() => props.onHide(false)}
                />
              </div>
            </div>
            <div className='small'>{podcastTypes[type].description}</div>
            <div>
              <div className='mt-2'>
                <span className='form-check-label fw-bold'>Video URL *</span>
                <input
                  type='text'
                  className='form-control'
                  value={videoUrl || ""}
                  onChange={onVideoUrlChange}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='d-flex justify-content-center mt-3'>
              <div dangerouslySetInnerHTML={{ __html: videoEmbedCode }} />
            </div>
          </>
        )}

        {type === "dataBase" && (
          <>
            <div className='d-flex justify-content-between'>
              <div className='cnow-form-title'>SEARCH FOR PODCASTS IN THE CULTURENOW MEDIA DATABASE</div>
              <div>
                <Button
                  size='sm'
                  label='Save'
                  color='light'
                  onClick={() => handleSave(selectedItems)}
                  className='me-2'
                />
                <Button
                  size='sm'
                  icon='close'
                  onClick={() => props.onHide(false)}
                />
              </div>
            </div>
            <hr />
            <div className='row g-1 mt-5'>
              <div className='col'>
                <input className='form-control bg-light border-start-0' type='text' placeholder='Search for podcast from database...' value={keyword}
                  onChange={(event) => { setKeyword(event.target.value) }}
                  onKeyDown={(e) => { if (e.key === 'Enter') { search(); } }}
                  autoComplete="off"
                />
              </div>
              <div className='col-auto'>
                <div className='btn-list'>
                  <Button label='Submit' onClick={() => { search(); }} />
                </div>
              </div>
            </div>

            <Spinner display={spinner}>
              <div className='mt-2'>
                <table className='table small'>
                  <thead>
                    <tr className='bg-light small'>
                      <th style={{ width: "100px" }}>Thumbnail</th>
                      <th style={{ width: "150px" }}>Podcast ID</th>
                      <th style={{ width: "270px" }}>Podcast Name</th>
                      <th>Related Sites</th>
                      <th style={{ width: "200px" }}>Podcaster/Author</th>
                      <th>Public Tags</th>
                      <th className='text-center'>Select Podcast</th>
                    </tr>
                  </thead>

                  <tbody>
                    {searchResult && searchResult.hits && searchResult.hits.map((item, index) => {
                      const isChecked = selectedItems?.findIndex(x => x.podcast_id === item?._source?.id) >= 0;
                      return (
                        <tr key={index}>
                          <td>
                            <ImageViewer entityType={'podcast'} entityId={item?._source?.id} type='thumb@2x' cssClass='thumb' />
                          </td>
                          <td>{item?._source?.id}</td>
                          <td>
                            <LongTextView>{item?._source?.name}</LongTextView>
                          </td>
                          <RelatedItemList data={item?._source?.related_items} />
                          <CreditList data={item?._source?.credits} />
                          <TagList data={item?._source?.tags} />
                          <td>
                            <div className='form-check align d-flex justify-content-center'>
                              {
                                <input
                                  disabled={entity?.podcasts && entity?.podcasts.findIndex(x => x?.podcast_id === item?._source?.id) >= 0 ? true : false}
                                  id={item?._source?.id}
                                  className='form-check-input'
                                  type='checkbox' name='select_podcast' defaultChecked={isChecked}
                                  onChange={(e) => { handleSelect(item?._source) }} />
                              }
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Spinner>
          </>
        )}

        {files?.length > 0 && (
          <>
            <div className='small fw-bold'>
              You’ve successfully uploaded {podcasts?.entityPodcasts?.length || 1} out of{" "}
              {files?.length} file(s)
            </div>
            <aside className='row my-1 g-2'>
              {files.map((file, index) => {
                const spinner = file?.url && file?.url.length > 0 ? false : true;
                return (
                  <div key={index} className='col-auto d-flex flex-column align-items-left'>
                    <Spinner display={spinner}>
                      <ImageComponent className='thumb' src={window.location.origin + '/img/thumb_podcast.png'} alt='thumb_podcast' />
                      <span className='fz-12'>{file.name.substring(0, 8) + "..." + file.name.substring(file.name.length - 3)}</span>
                    </Spinner>
                  </div>
                );
              })}
            </aside>

            {!props.updateID && podcasts?.entityPodcasts?.length === files?.length && (
              <div className='mt-2'>
                <Button
                  label='Add description for all podcasts'
                  color='danger'
                  onClick={handleNext}
                />
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PodcastUploader;
