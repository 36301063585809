/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import Button from "../buttons/default";
import TextEditor from "./textEditor";
import PreviewButton from "../buttons/preview";
import LinkButton from "../buttons/link";
import ImageViewer from "../viewers/image";
import SelectPlaylist from "../selects/playlist";
import { handleReorder, newTempId } from '../../helpers';
import ImageComponent from '../image';
const _api = require('../../api')

const RelatedPlaylist = (props) => {
  const dispatch = useDispatch();
  const entity = useSelector((state) => state[props.id]);

  // Dragula Start
  const [dragula, setDragula] = useState(uniqid())
  const dragulaRef = useRef()
  const dragulaContainer = 'dragula-container-related-playlist'

  useEffect(() => {
    let containers = []
    containers.push(document.getElementById(dragulaContainer))
    dragulaRef.current = Dragula(containers, {})
    dragulaRef.current.on('drop', () => {
      const items = handleReorder(entity.playlists, null, dragulaContainer)
      setDragula(uniqid())
      dispatch({ type: 'entity', payload: { ...entity, playlists: items } })
      props.refresh && props.refresh()
    })

  }, [dragula])
  // Dragula End

  const addRelatedPlaylist = () => {
    if (!entity?.playlists) entity.playlists = [];
    if (props.type === 'playlist') {
      entity.playlists.push({
        id: newTempId(),
        [`${props.type}_id`]: props.id,
        related_playlist_id: null,
        related_playlist_name: "",
        description: "",
        source: props.type,
        sort: null
      });
    } else {
      entity.playlists.push({
        id: newTempId(),
        [`${props.type}_id`]: props.id,
        playlist_id: null,
        playlist_name: "",
        description: "",
        source: props.type,
        sort: null
      });
    }
    entity.playlists.forEach((item, index) => item.sort = index + 1)
    setDragula(uniqid())
    dispatch({ type: "entity", payload: { ...entity, playlists: entity.playlists } });
  };

  const handlePlaylist = (index, value) => {
    if (props.type === 'playlist') {
      entity.playlists[index].related_playlist_id = value.id;
      entity.playlists[index].related_playlist_name = value.name;
      entity.playlists[index].image_url = value.image;
    } else {
      entity.playlists[index].playlist_id = value.id;
      entity.playlists[index].playlist_name = value.name;
      entity.playlists[index].image_url = value.image;
    }
    dispatch({ type: "entity", payload: { ...entity, playlists: entity.playlists } });
  };

  const handleEditorChange = (index, value) => {
    entity.playlists[index].description = value;
    dispatch({ type: "entity", payload: { ...entity, playlists: entity.playlists } });
  };

  const removeRelatedPlaylist = async (index) => {
    if (entity.playlists[index]) {
      if (!entity.playlists[index].id?.startsWith('temp.')) {
        if (props.type === 'playlist') {
          await _api[props.type].deleteRelatedPlaylist(entity.playlists[index].id)
        } else {
          await _api[props.type].deletePlaylist(entity.playlists[index].id)
        }
      }
      entity.playlists.splice(index, 1)
      entity.playlists.forEach((item, index) => item.sort = index + 1)
      dispatch({ type: 'entity', payload: { ...entity, playlists: entity.playlists } });
    }
  };

  return (
    <>
      <hr className='cnow-hr' />
      <div className='d-flex justify-content-between'>
        <div className='cnow-form-title'>Related Playlist</div>
        <Button size='md' label='Add a Playlist' icon='plus' onClick={() => addRelatedPlaylist()} />
      </div>
      <div id={dragulaContainer} key={dragula} className="table table-borderless">
        {entity?.playlists?.length > 0 && entity?.playlists.map((item, index) => {
          return (
            <div key={index} id={item.id} className='mb-3 dragula-item'>
              <div className='d-flex'>
                <div className={entity?.playlists?.length > 1 ? "col-auto" : "d-none"}>
                  <label className='cnow-form-label fw-bold'>Order</label>
                  <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete='off' />
                </div>
                <div className={entity?.playlists?.length > 1 ? "ms-2" : ""}>
                  <div className=''>&nbsp;</div>
                  {/* <ImageViewer url={item?.image_url} type='thumb' cssClass='thumb' key={item.id} /> */}
                  <div className='position-relative'>
                    <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                      {item?.image_url && <ImageComponent src="/img/icons/playlist.svg" style={{ width: '100%', height: '100%' }} alt="playlist" />}
                    </div>
                    <ImageViewer url={item?.image_url} type='thumb' cssClass='thumb' key={item.id} />
                  </div>
                </div>
                <div className='col'>
                  <div className='row g-1'>
                    <div className='col-auto d-flex flex-grow-1'>
                      <div className='w-100'>
                        <label className='cnow-form-label fw-bold'>Related Playlist *</label>
                        {!item.id.startsWith('temp.') ?
                          <input value={props.type === 'playlist' ? item?.related_playlist_name : item?.playlist_name} disabled className='w-100' style={{ height: '38px' }} />
                          :
                          <SelectPlaylist key={item.id}
                            value={{
                              id: props.type === 'playlist' ? item?.related_playlist_id : item?.playlist_id,
                              name: props.type === 'playlist' ? item?.related_playlist_name : item?.playlist_name
                            }}
                            onChange={(value) => handlePlaylist(index, value)} />}
                      </div>
                    </div>
                    <div className='col-auto'>
                      <label className='cnow-form-label'></label>
                      <div className='btn-list'>
                        <PreviewButton size='md' link={`playlist/${props.type === 'playlist' ? item?.related_playlist_id : item?.playlist_id}`} />
                        <LinkButton icon='edit' size='md' to={`/playlist/${props.type === 'playlist' ? item?.related_playlist_id : item?.playlist_id}/editor`} />
                        <Button icon='delete' size='md' onClick={() => removeRelatedPlaylist(index)} />
                      </div>
                    </div>
                  </div>

                  <TextEditor key={item.id} data={item.description} onChange={(data) => handleEditorChange(index, data)}>
                    <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                  </TextEditor>
                </div>
              </div>
            </div>
          );
        })
        }
      </div>
    </>
  );
};

export default RelatedPlaylist;